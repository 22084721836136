import React, { useState, useEffect } from "react";
import TopBar from "../menu/TopBar";
import {Link} from "react-router-dom";
import axios from "axios";
import "../member/MemberList.scss"
import {isNull, refreshToken} from "../../Common";
import moment from "moment";

function getReportList(pageNumber, onLoad, onFail) {

    axios.get(
        "/admin/chatting/report/list",
        {
            params: {
                memberUUID: sessionStorage.getItem("memberUUID"),
                page: pageNumber
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getReportList(pageNumber, onLoad, onFail);
            });
        } else {
            onFail(error);
        }
    });
}

function ReportList(props) {
    let pageNum = parseInt(props.match.params.pageNumber) - 1
    let [reportData, setReportData] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        getReportList(pageNum, (responseData) => {
            setReportData(responseData);
        }, (error) => {
            alert("조회 실패");
            console.log(error);
        });

    }, [props.match.params.pageNumber]);

    let trList = isNull(reportData) || isNull(reportData.content) ? <tr className="report"><td colSpan={6}>신고 내역이 없습니다.</td></tr> : reportData.content.map((data) =>
        <tr className="report" key={data.reportId}>
            <td>{data.reporterId}</td>
            <td>{data.reporterNickname}</td>
            <td>{data.content}</td>
            <td>{data.targetMemberId}</td>
            <td>{data.targetMemberNickname}</td>
            <td>{moment(data.createdDate).format("yyyy-MM-dd HH:mm:ss")}</td>
        </tr>
    );

    if (isNull(reportData)) return "";

    let minPage = pageNum - (pageNum % 10) + 1
    let maxPage = pageNum - (pageNum % 10) + 10
    if (maxPage > reportData.totalPages) {
        maxPage = reportData.totalPages
    }

    let pageList = [];

    for (let i = minPage; i <= maxPage; i++) {
        const url = `/member/list/MANAGER/${i}?`;

        pageList.push(
            <Link to={url} key={i}>
                {i}
            </Link>
        );
    }
    if (minPage > 1) {
        const url = `/member/list/MANAGER/${minPage - 1}?`;

        pageList.unshift(
            <Link to={url} key={'prev'}>
                이전
            </Link>
        )
    }
    if (maxPage < reportData.totalPages) {
        const url = `/member/list/MANAGER/${maxPage + 1}?`;

        pageList.push(
            <Link to={url} key={'next'}>
                다음
            </Link>
        )
    }

    return (
        <>
            <TopBar/>
            <div className="MemberListContainer">
                <div className="TableContainer">
                    <table>
                        <thead>
                        <tr>
                            <th colSpan={2}>신고자</th>
                            <th>신고 대상 메시지</th>
                            <th colSpan={2}>신고 대상자</th>
                            <th>신고 일시</th>
                        </tr>
                        </thead>
                        <tbody>
                        {trList}
                        </tbody>
                    </table>
                    <div className="ButtonList">
                        <div className="spacer"/>
                        <div className="PageList">
                            {pageList}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportList;