import React, {useEffect, useState} from "react";
import CircleImage from "../common/CircleImage";
import axios from "axios";
import {isNull, refreshToken} from "../../Common";

function getMacros(memberId, onLoad, onFail) {

    axios.get(
        "/admin/dummy/macro/available/list",
        {
            params: {
                memberId: memberId
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMacros(memberId, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function addDummyMacro(requestData, onLoad, onFail) {
    axios.post(
        "/admin/dummy/macro",
        requestData,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        onLoad();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                addDummyMacro(requestData, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function removeDummyMacro(memberId, macroId, onLoad, onFail) {
    axios.delete(
        "/admin/dummy/macro",
        {
            params: {
                memberId: memberId,
                macroId: macroId
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        onLoad();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeDummyMacro(memberId, macroId, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function modifyMember(modifyInfo, image, onFail) {
    let data = new FormData();
    for (const [key, value] of Object.entries(modifyInfo)) {
        data.append(key, value);
    }
    data.append("image", image);

    axios.put("/admin/member", data, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken"),
                "Content-Type": "multipart/form-data"
            }
        }
    ).then(() => {
        alert("수정되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyMember(modifyInfo, image, onFail);
            });
        } else {
            onFail();
            alert("수정 실패");
            console.log(error);
        }
    });
}

function removeMember(uuid, onSuccess, onFail) {
    axios.delete("/admin/member", {
            params: {uuid: uuid},
            headers: {Authorization: sessionStorage.getItem("accessToken")}
        }
    ).then(() => {
        alert("삭제되었습니다.");
        onSuccess();
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeMember(uuid, onFail);
            });
        } else {
            onFail();
            alert("삭제 실패");
            console.log(error);
        }
    });
}

function modifyDummyResponseTime(modifyInfo, onSuccess, onFail) {
    axios.put("/admin/dummy/responseTime", modifyInfo, {
            headers: {Authorization: sessionStorage.getItem("accessToken")}
        }
    ).then(() => {
        alert("응답 속도가 설정되었습니다.");
    }).then(() => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyDummyResponseTime(modifyInfo, onFail);
            });
        } else {
            onFail();
            alert("삭제 실패");
            console.log(error);
        }
    });
}

function DummyMemberDetail(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [isModify, setIsModify] = useState(false);
    let [nameInput, setNameInput] = useState(props.memberInfo.name);
    let [nicknameInput, setNicknameInput] = useState(props.memberInfo.nickname);
    let [imageInput, setImageInput] = useState("");
    let [imageForDisplay, setImageForDisplay] = useState(props.memberInfo.imageUrl);
    let [macroData, setMacroData] = useState([]);
    let [responseTimeInput, setResponseTimeInput] = useState(0);

    const nameInputChanged = e => setNameInput(e.target.value);
    const nicknameInputChanged = e => setNicknameInput(e.target.value);
    const imageInputChanged = ev => {
        if (ev.target.files && ev.target.files[0]) {
            setImageInput(ev.target.files[0]);

            let reader = new FileReader();
            reader.onload = e => {
                setImageForDisplay(e.target.result);
            }
            reader.readAsDataURL(ev.target.files[0]);
        }
    }
    const responseTimeInputChanged = e => setResponseTimeInput(e.target.value);

    useEffect(() => {
        setNameInput(props.memberInfo.name);
        setNicknameInput(props.memberInfo.nickname);
        setResponseTimeInput(props.memberInfo.dummyResponseTime === undefined || props.memberInfo.dummyResponseTime === null || props.memberInfo.dummyResponseTime.length === 0 ? 0 : props.memberInfo.dummyResponseTime);

        getMacros(props.memberInfo.uuid,
            (responseData) => {
                setMacroData(responseData);
            }, () => {
                alert("매크로 목록 조회 실패");
            });
    }, [props.memberInfo]);

    let infoTextArea = isModify ? (
        <div className="InfoTextArea">
            <span>{props.memberInfo.id}</span>
            <input type="text" value={nameInput} onChange={nameInputChanged}/>
            <input type="text" value={nicknameInput} onChange={nicknameInputChanged}/>
        </div>
    ) : (
        <div className="InfoTextArea">
            <span>{props.memberInfo.id}</span>
            <span>{nameInput}</span>
            <span>{nicknameInput}</span>
        </div>
    );

    let buttonArea = isModify ? (
        <div className="ButtonArea">
            <button className="OK" onClick={() => {
                if (isLoading || !window.confirm("정말 수정하시겠습니까?")) return;
                setIsLoading(true);

                modifyMember({
                    uuid: props.memberInfo.uuid,
                    name: nameInput,
                    nickname: nicknameInput
                }, image => {
                    // todo 이미지 수정 되는지 확인 문법 오류 있을 수도
                    setIsLoading(false);
                    imageInput(image);
                }, () => {
                    setIsLoading(false);
                })
            }}>확인
            </button>
            <button className="Cancel" onClick={() => {
                setImageForDisplay(props.memberInfo.imageUrl);
                setIsModify(false);
            }}>취소
            </button>
        </div>
    ) : (
        <div className="ButtonArea">
            <button className="Modify" onClick={() => {
                setIsModify(true);
            }}>수정
            </button>
            <button className="Remove" onClick={event => {
                if (isLoading || !window.confirm("정말 삭제하시겠습니까?")) return;
                setIsLoading(true);

                removeMember(props.memberInfo.uuid,
                    () => {
                        setIsLoading(false);
                    }, () => {
                        setIsLoading(false);
                    });
            }}>삭제
            </button>
        </div>
    );

    let dummyMacroList = isNull(props.memberInfo.dummyMacroList) ? "" :
        props.memberInfo.dummyMacroList.map(data =>
            <div className="MacroListItem" key={data.id}>
                <span>{data.text}</span>
                <button className="remove" onClick={event => {
                    if (isLoading || !window.confirm("정말 이 더미 회원의 매크로 목록에서 삭제하시겠습니까?")) return;
                    setIsLoading(true);

                    removeDummyMacro(props.memberInfo.uuid, data.id, () => {
                        alert("매크로 목록 삭제 성공");
                        let modal = document.querySelector("#AddDummyMacroModal")
                        modal.style.display = "none";
                        props.refresh();

                        getMacros(props.memberInfo.uuid,
                            (responseData) => {
                                setIsLoading(false);
                                setMacroData(responseData);
                            }, () => {
                                setIsLoading(false);
                                alert("매크로 목록 조회 실패");
                            });
                    }, () => {
                        setIsLoading(false);
                        alert("매크로 목록 삭제 실패");
                    });
                }}>삭제
                </button>
            </div>
        );

    let macroList = macroData === undefined || macroData === null || macroData.length === 0 ? "" :
        macroData.map(data =>
            <option key={data.id} value={data.id}>{data.text}</option>
        );

    window.onclick = ev => {
        let modal = document.querySelector("#AddDummyMacroModal")
        if (ev.target === modal) {
            modal.style.display = "none";
        }
    }

    return (
        <>
            <div className="MemberDetailContainer">

                <h1>더미 회원 정보</h1>
                <div className="MemberInfoArea">
                    <div className="CircleImageArea">
                        <input type="file" style={{display: "none"}} id="profileImgInput" onChange={imageInputChanged}
                               accept="image/*"/>
                        <CircleImage imgSrc={isNull(imageForDisplay) ? props.memberInfo.imageUrl : imageForDisplay}
                                     name={props.memberInfo.name} width='100%' onMouseOver={e => {
                            if (isModify) {
                                e.target.style.cursor = "pointer";
                            } else {
                                e.target.style.cursor = "";
                            }
                        }} onClick={() => {
                            if (isModify) {
                                document.querySelector("#profileImgInput").click();
                            }
                        }}/>
                    </div>
                    {infoTextArea}
                    {buttonArea}
                </div>
                <h1>응답 매크로 설정</h1>
                <h2>매크로 목록 <button className="AddMacroButton" onClick={() => {
                    if (macroData === undefined || macroData === null || macroData.length === 0) {
                        alert("모든 매크로가 추가되어있습니다.");
                    } else {
                        document.querySelector("#AddDummyMacroModal").style.display = "block";
                    }
                }}>추가</button></h2>
                <div className="MacroList">
                    {dummyMacroList}
                </div>
                <h2>응답속도</h2>
                <p>관리자 메시지 전송 후 응답 매크로 전송 시간(초)</p>
                <input type="number" min={0} value={responseTimeInput} onChange={responseTimeInputChanged}
                       style={{marginRight: "10px"}}/>
                <button className="ModifyResponseTimeButton" onClick={() => {
                    if (isLoading || !window.confirm("이 더미 회원의 응답 속도를 수정하시겠습니까?")) return;
                    setIsLoading(true);
                    modifyDummyResponseTime({
                        uuid: props.memberInfo.uuid,
                        responseTime: responseTimeInput
                    }, () => {
                        setIsLoading(false);
                    }, () => {
                        setIsLoading(false);
                    });
                }}>수정
                </button>
            </div>

            {/* The Modal */}
            <div id="AddDummyMacroModal" className="modal">

                {/* Modal content */}
                <div className="modal-content">
                    <span className="close" onClick={() => {
                        document.querySelector("#AddDummyMacroModal").style.display = "none";
                    }}>&times;</span>
                    <h1>매크로 목록 추가</h1>
                    <div className="MemberForm">
                        <div className="InputList">
                            <select id="MacroSelect">
                                {macroList}
                            </select>
                        </div>

                        <div className="ButtonList">
                            <button className="OK" onClick={() => {
                                if (isLoading) return;
                                setIsLoading(true);

                                addDummyMacro({
                                    memberId: props.memberInfo.uuid,
                                    macroId: document.querySelector("#MacroSelect").value
                                }, () => {
                                    alert("매크로 목록 추가 성공");
                                    let modal = document.querySelector("#AddDummyMacroModal")
                                    modal.style.display = "none";
                                    props.refresh();

                                    getMacros(props.memberInfo.uuid,
                                        (responseData) => {
                                            setIsLoading(false);
                                            setMacroData(responseData);
                                        }, () => {
                                            setIsLoading(false);
                                            alert("매크로 목록 조회 실패");
                                        });
                                }, () => {
                                    setIsLoading(false);
                                    alert("매크로 목록 추가 실패");
                                });
                            }}>등록
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default DummyMemberDetail;