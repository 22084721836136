import React, { useEffect, useState } from "react"
import axios from "axios"
import "./Login.scss"
import * as animationData from "../../data/loading.json"
import Lottie from 'react-lottie'

function attemptLogin(id, pwd, onFail) {
    // console.log(login(id, pwd, onFail))
    // console.log(checkIpIsValid(response.data.IPv4))
    
    login(id, pwd, onFail)
    axios.get('https://geolocation-db.com/json/')
        .then(response => {
            console.log(response.data.IPv4)
            checkIpIsValid(response.data.IPv4, () => {
                login(id, pwd, onFail)
            }, () => {
                alert("유효하지 않은 ip 입니다.")
                onFail()
            })
        }).catch(error => {
            console.log(error)
            alert("유효하지 않은 ip 입니다..")
            onFail()
        })
}

function checkIpIsValid(ip, onSuccess, onFail) {
    axios.post(
        "/admin/ip/valid",
        {
            ip: ip
        },
        {
            headers: {
                Authorization: "Basic ZnV0dXJlaW52ZXN0OmZ1dHVyZXBhc3N3b3Jk"
            }
        }
    ).then(response => {
        const isValid = response.data
        console.log(isValid)
        if (isValid) {
            onSuccess()
        } else {
            onFail()
        }
    }).catch(error => {
        onFail()
    })
}

function login(id, pwd, onFail) {
    axios.post(
        "/login",
        {
            id: id,
            pwd: pwd
        },
        {
            headers: {
                Authorization: "Basic ZnV0dXJlaW52ZXN0OmZ1dHVyZXBhc3N3b3Jk"
            }
        }
    ).then(response => {
        sessionStorage.setItem("memberUUID", response.data.uuid)
        sessionStorage.setItem("memberType", response.data.memberType)
        sessionStorage.setItem("accessToken", response.data.memberTokenInfo.accessToken)
        sessionStorage.setItem("refreshToken", response.data.memberTokenInfo.refreshToken)
        window.location.href = "/"
    }).catch(error => {
        console.log('error', error)
        let code = error.response.data.code

        if (code === 605) {
            alert(error.response.data.message)
        } else {
            alert("아이디와 비밀번호가 올바른지 확인하세요.")
        }
        onFail()
    })
}

function Login() {
    let [id, setId] = useState("")
    let [pwd, setPwd] = useState("")
    let [isLoading, setIsLoading] = useState(false)

    const idInputChanged = e => setId(e.target.value)
    const pwdInputChanged = e => setPwd(e.target.value)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice'
        // }
    }

    let progressView = <div className="ProgressView">
        <Lottie options={defaultOptions}
        />
    </div>
    let content = isLoading ? progressView : <div className="LoginContainer">
        <div className="LoginBox">
            <h1>로그인</h1>
            <div className="InputWrapper">
                <input type="text" name="id" placeholder="아이디를 입력하세요." value={id} onChange={idInputChanged} />
            </div>
            <div className="InputWrapper">
                <input type="password" name="pwd" placeholder="비밀번호를 입력하세요." value={pwd} onChange={pwdInputChanged}
                    onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                            setIsLoading(true)
                            attemptLogin(id, pwd, () => {
                                setIsLoading(false)
                            })
                        }
                    }} />
            </div>
            <button onClick={event => {
                setIsLoading(true)
                attemptLogin(id, pwd, () => {
                    setIsLoading(false)
                })
            }}>로그인
            </button>
        </div>
    </div>

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}

export default Login
