import React, {useState} from "react";
import axios from "axios";
import {refreshToken} from "../../Common";

function addMacro(macro, onFail) {
    axios.post(
        "/admin/macro",
        macro,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("매크로 텍스트가 추가되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                addMacro(macro, onFail);
            });
        } else {
            onFail();
            alert("매크로 추가 실패");
            console.log(error);
        }
    });
}

function AddMacroModal() {
    let [isLoading, setIsLoading] = useState(false);
    let [textInput, setTextInput] = useState("");

    const textInputChanged = e => setTextInput(e.target.value);

    return (
        <div id="AddMacroModal" className="modal">

            {/* Modal content */}
            <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#AddMacroModal").style.display = "none";
                    }}>&times;</span>
                <h1>매크로 텍스트 등록</h1>
                <div className="MacroForm">
                    <div className="InputList">
                        <input type="text" placeholder="내용 입력" value={textInput} onChange={textInputChanged}/>
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={event => {
                            if (isLoading) return;
                            setIsLoading(true);

                            addMacro({
                                text: textInput
                            }, () => {
                                setIsLoading(false);
                            });
                        }}>등록
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AddMacroModal;