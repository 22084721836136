import React, { useEffect, useRef, useState } from "react"
import "./Chat.scss"
import ChattingRoomListItem from "./ChattingRoomListItem"
import TopBar from "../menu/TopBar"
import AddChatRoomView from "./AddChatRoomView"
import ChattingView from "./ChattingView"
import axios from "axios"
import { isNull, refreshToken } from "../../Common"
import QueryString from "qs"
import * as StompJS from "@stomp/stompjs"

const ChatScreenMode = Object.freeze({
    FIRST_SCREEN: Symbol(0),
    ADD_CHAT_ROOM: Symbol(1),
    ON_CHAT: Symbol(2)
})

function getChattingRooms(keyword, onLoad, onFail) {
    let params = {
        memberUUID: sessionStorage.getItem("memberUUID")
    }
    if (!isNull(keyword)) params.keyword = keyword

    axios.get("/chatting/chattingRoom", {
        params: params,
        headers: {
            Authorization: sessionStorage.getItem("accessToken")
        }
    }
    ).then(response => {
        onLoad(response.data)
    }).catch(error => {
        let code = error.response.data.code

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getChattingRooms(keyword, onLoad, onFail)
            })
        } else {
            console.log(error)
            onFail()
        }
    })
}

function ChattingMain(props) {

    const params = QueryString.parse(props.location.search.replace('?', ''))
    const client = useRef({})

    let [mode, setMode] = useState(ChatScreenMode.FIRST_SCREEN)
    let [keyword, setKeyword] = useState("")
    let [chattingRoomInfo, setChattingRoomInfo] = useState()
    let [chattingRoomData, setChattingRoomData] = useState([])

    const keywordChanged = e => setKeyword(e.target.value)

    useEffect(() => {
        /** 채팅방 리스트 조회 **/
        getChattingRooms(null, (responseData) => {
            setChattingRoomData(responseData.sort((a, b) => {
                if (a.lastMessageCreatedDate > b.lastMessageCreatedDate) return -1
                if (a.lastMessageCreatedDate < b.lastMessageCreatedDate) return 1

                if (a.chattingRoomTitle > b.chattingRoomTitle) return -1
                else return 1
            }))
            if (!isNull(params.chattingRoomId)) {
                const chattingRoomInfo = responseData.find(chattingRoom => chattingRoom.chattingRoomId === parseInt(params.chattingRoomId))

                setChattingRoomInfo(chattingRoomInfo)
                setMode(ChatScreenMode.ON_CHAT)
            }
            console.log('chattingRoomData = ' + JSON.stringify(chattingRoomData))
            connect(responseData)
        }, () => {
            alert("채널 목록 조회 실패")
        })
        return () => {
            disconnect()
        }
    }, [])

    const disconnect = () => {
        //채팅 연결 종료 메시지
        sessionStorage.setItem('selectChattingRoomId', -1)
        client.current.deactivate()
    }

    const connect = (chattingRooms) => {
        const brokerURL = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:8080/websocket/invest"

        client.current = new StompJS.Client({
            brokerURL: brokerURL,
            connectHeaders: {
                Authorization: sessionStorage.getItem("accessToken")
            },
            debug: (str) => {
                console.log(str)
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => {
                subscribe(chattingRooms)
            },
            onStompError: (frame) => {
                if (frame.headers.message.includes("invalid token")) {
                    refreshToken(() => {
                        client.current.deactivate()
                            .then(() => {
                                connect()
                            }).catch(error => {
                                console.log(error)
                            })
                    })
                } else {
                    alert("채팅방 연결 실패")
                    console.log(frame)
                }
            }
        })
        client.current.activate()
    }

    /** 현재 채팅방 새로운 메시지 구독 **/
    const subscribe = (chattingRooms) => {
        chattingRooms.forEach(chattingRoom => {
            /** 문자 메세지 **/
            client.current.subscribe(`/topic/chatting/sub/admin/newMessage/${chattingRoom.chattingRoomId}`, (data) => {
                updateBadgeCount(chattingRoom.chattingRoomId, data)
            })
            /** 파일 메세지 **/
            client.current.subscribe(`/topic/chatting/sub/admin/newFileMessage/${chattingRoom.chattingRoomId}`, (data) => {
                updateBadgeCount(chattingRoom.chattingRoomId, data)
            })
        })
    }

    /** 뱃지 카운트 업데이트 **/
    const updateBadgeCount = (chattingRoomId, data) => {
        const selectChattingRoomId = sessionStorage.getItem('selectChattingRoomId')
        let newMessage = JSON.parse(data.body)
        setChattingRoomData(chattingRooms => chattingRooms.map(c => {
            if (c.chattingRoomId === chattingRoomId) {
                if (Number(selectChattingRoomId) !== Number(chattingRoomId)) {
                    c.unreadMessageCount++
                }
                c.lastMessageContent = newMessage.content
                return c
            } else {
                return c
            }
        }))
    }

    /** 채팅방 리스트  뷰 생성 **/
    const list = isNull(chattingRoomData) ? "" : chattingRoomData.map(data => {
        return <ChattingRoomListItem
            key={data.chattingRoomId}
            chattingRoomInfo={data}
            onClick={() => {
                sessionStorage.setItem('selectChattingRoomId', data.chattingRoomId)
                setChattingRoomData(chattingRooms => chattingRooms.map(c => {
                    if (c.chattingRoomId === data.chattingRoomId) {
                        c.unreadMessageCount = 0
                    }
                    return c
                }))
                setChattingRoomInfo(data)
                setMode(ChatScreenMode.ON_CHAT)
            }}
        />
    }
    )

    let content = ""
    switch (mode) {
        case ChatScreenMode.FIRST_SCREEN:
            content = (
                <React.Fragment>
                    <section id="FirstScreenView">
                        <button className="ShowAddChatRoomViewButton" onClick={event => {
                            setMode(ChatScreenMode.ADD_CHAT_ROOM)
                        }}>채널 생성하기
                        </button>
                    </section>
                </React.Fragment>
            )

            break
        case ChatScreenMode.ADD_CHAT_ROOM:
            content = <AddChatRoomView onClose={() => {
                setMode(ChatScreenMode.FIRST_SCREEN)
            }} />

            break
        case ChatScreenMode.ON_CHAT:
            content = <ChattingView chattingRoomInfo={chattingRoomInfo} onClose={() => {
                setMode(ChatScreenMode.FIRST_SCREEN)
            }} />

            break
    }

    return (
        <>
            <TopBar />
            <div id="ChatScreen">
                <section id="ChannelList">
                    <div id="ChannelSearch">
                        <div id="SearchBox">
                            <input id="SearchBar" type="text" placeholder="이름 검색"
                                style={{ backgroundImage: `url('/images/search.png')` }}
                                value={keyword} onChange={keywordChanged}
                                onKeyUp={(e) => {
                                    if (e.keyCode === 13) { //enter
                                        getChattingRooms(keyword, (responseData) => {
                                            setChattingRoomData(responseData)
                                        }, () => {
                                            alert("채널 목록 조회 실패")
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div id="Items">
                        {list}
                    </div>
                </section>

                {content}
            </div>
        </>
    )
}

export default ChattingMain
