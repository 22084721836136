import React, {useState} from "react";
import CircleImage from "../common/CircleImage";
import axios from "axios";
import {isNull, refreshToken} from "../../Common";

function confirmMember(uuid, onFail) {
    axios.put(
        "/admin/member/confirm",
        {
            uuid: uuid
        }, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("회원 가입을 승인하였습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                confirmMember(uuid, onFail);
            });
        } else {
            onFail();
            console.log(error);
        }
    });
}

function cancelMemberConfirm(uuid, id, onFail) {
    axios.put(
        "/admin/member/confirm/cancel",
        {
            uuid: uuid
        }, {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert(`'${id}' 회원의 승인이 취소되었습니다.`);
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                cancelMemberConfirm(uuid, id, onFail);
            });
        } else {
            onFail();
            console.log(error);
        }
    });
}

function removeChattingMember(removeInfo, onSuccess, onFail) {
    axios.delete(
        "/admin/chatting/chattingRoom/member",
        {
            params: removeInfo,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeChattingMember(removeInfo, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function modifyPwd(memberUUID, pwd, onSuccess, onFail) {
    let data = new FormData();
    data.append("uuid", memberUUID);
    data.append("pwd", pwd);

    axios.put(
        "/admin/member/pwd",
        data,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyPwd(memberUUID, pwd, onSuccess, onFail)
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function MemberDetail(props) {

    let [isLoading, setIsLoading] = useState(false);
    let [pwdInput, setPwdInput] = useState("");

    const pwdInputChanged = e => setPwdInput(e.target.value);

    let confirmStatusButton = props.memberInfo.isConfirmed ? <button className="Confirmed" onMouseOver={event => {
        event.target.innerHTML = "승인 취소";
    }} onMouseLeave={event => {
        event.target.innerHTML = "승인";
    }} onClick={event => {
        if(isLoading || !window.confirm(`'${props.memberInfo.id}' 회원이 모든 채널에서 퇴출됩니다. 정말 승인을 취소하시겠습니까?`)) return;
        setIsLoading(true);

        cancelMemberConfirm(props.memberInfo.uuid, props.memberInfo.id, () => {
            setIsLoading(false);
            alert("승인 취소 실패");
        });
    }}>승인</button> : <button className="NotConfirmed" onClick={event => {
        if (isLoading || !window.confirm("회원 가입을 승인하시겠습니까?")) return;
        setIsLoading(true);

        confirmMember(props.memberInfo.uuid, () => {
            setIsLoading(false);
            alert("승인 실패");
        });
    }}>승인 필요</button>

    let chattingRoomList = isNull(props.memberInfo) || isNull(props.memberInfo.chattingRooms) ? <tr><td colSpan={3}>아직 참여한 채팅이 없습니다.</td></tr> : props.memberInfo.chattingRooms.map(data => {
        let chattingRoomType = "";
        switch (data.chattingRoomType) {
            case "PUBLIC":
                chattingRoomType = "공개";
                break;
            case "PRIVATE":
                chattingRoomType = "비공개";
                break;
            case "DUMMY":
                chattingRoomType = "더미";
                break;
            default:
        }
        let chattingRoomStatus = "";
        switch (data.chattingRoomStatus) {
            case "ACTIVITY":
                chattingRoomStatus = "활동";
                break;
            case "INACTIVITY":
                chattingRoomStatus = "비활동";
                break;
            case "REMOVE":
                chattingRoomStatus = "삭제";
                break;
            default:
        }

        return <tr key={data.chattingRoomId}>
            <td>{data.chattingRoomTitle}</td>
            <td>{`${chattingRoomType} / ${chattingRoomStatus} / ${data.chattingRoomMemberCount}명`}</td>
            <td><button className="remove" onClick={() => {
                if (isLoading || !window.confirm(`'${props.memberInfo.nickname}' 회원을 퇴장시키시겠습니까?`)) return
                setIsLoading(true);

                removeChattingMember({
                    chattingRoomId: data.chattingRoomId,
                    memberUUID: props.memberInfo.uuid
                }, () => {
                    props.onReload();
                }, () => {
                    setIsLoading(false);
                    alert("퇴출 실패");
                });
            }}>퇴장</button></td>
        </tr>
    });

    window.onclick = ev => {
        let modal = document.querySelector("#ModifyPwdModal");
        if (ev.target === modal) {
            modal.style.display = "none";
        }
    }

    return (
        <React.Fragment>
            <div className="MemberDetailContainer">

                <h1>회원 상세 정보</h1>
                <div className="MemberInfoArea">
                    <div className="CircleImageArea">
                        <CircleImage imgSrc={props.memberInfo.imageUrl} name={props.memberInfo.name} width='100%'/>
                    </div>
                    <div className="InfoTextArea">
                        <span>{props.memberInfo.id}</span>
                        <span>{props.memberInfo.name}</span>
                        <span>{props.memberInfo.nickname}</span>
                        <span>{props.memberInfo.phone}</span>
                    </div>
                    <div className="ButtonArea">
                        {confirmStatusButton}
                    </div>
                </div>
                <h2>참여 채팅 리스트</h2>
                <div className="ChattingRoomList">
                    <table>
                        <tbody>
                        {chattingRoomList}
                        </tbody>
                    </table>
                </div>
                <div className="ModifyPasswordButtonContainer">
                    <button onClick={() => {
                        document.querySelector("#ModifyPwdModal").style.display = "block";
                    }}>비밀번호 변경</button>
                </div>
            </div>

            <div id="ModifyPwdModal" className="modal">

                {/* Modal content */}
                <div className="modal-content">
                    <span className="close" onClick={() => {
                        document.querySelector("#ModifyPwdModal").style.display = "none";
                    }}>&times;</span>
                    <h1>비밀번호 변경</h1>
                    <div className="MemberForm">
                        <div className="InputList">
                            <input type="password" placeholder="변경할 비밀번호를 입력하세요" value={pwdInput} onChange={pwdInputChanged}/>
                        </div>

                        <div className="ButtonList">
                            <button className="OK" onClick={() => {
                                if (isNull(pwdInput)) {
                                    alert("비밀번호를 입력하세요.")
                                    return;
                                }
                                if (isLoading || !window.confirm("이 회원의 비밀번호를 변경하시겠습니까?")) return;
                                setIsLoading(true);

                                modifyPwd(props.memberInfo.uuid, pwdInput, () => {
                                    alert("변경되었습니다.");
                                    props.onReload();
                                    setPwdInput("");
                                    document.querySelector("#ModifyPwdModal").style.display = "none";
                                }, () => {
                                    setIsLoading(false);
                                    alert("비밀번호 변경 실패");
                                })
                            }}>변경</button>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default MemberDetail;