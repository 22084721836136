import React, {useEffect, useState} from "react";
import TopBar from "./menu/TopBar";
import axios from "axios";
import {isNull, refreshToken, sleep} from "../Common";
import "./Home.scss";
import CircleImage from "./common/CircleImage";

function getMemberDetail(uuid, onLoad, onFail) {
    axios.get(
        "/admin/member/detail",
        {
            params: {
                uuid: uuid
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        const code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMemberDetail(uuid, onLoad, onFail);
            });
        } else {
            onFail(error);
        }
    });
}

function modifyMember(modifyInfo, image) {
    let data = new FormData();
    for (const [key, value] of Object.entries(modifyInfo)) {
        data.append(key, value);
    }
    data.append("image", image);

    axios.put("/admin/member", data,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken"),
                "Content-Type": "multipart/form-data"
            }
        }
    ).then(() => {
        alert("수정되었습니다.");
        sleep(1000);
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyMember(modifyInfo, image);
            });
        } else if (code === 603) {
            alert("닉네임 중복");
        } else {
            alert("수정 실패");
            console.log(error);
        }
    });
}

function Home() {

    let [memberData, setMemberData] = useState();
    let [isModify, setIsModify] = useState(false);
    let [nameInput, setNameInput] = useState("");
    let [nicknameInput, setNicknameInput] = useState("");
    let [imageInput, setImageInput] = useState();
    let [imageForDisplay, setImageForDisplay] = useState();

    const nameInputChanged = e => setNameInput(e.target.value);
    const nicknameInputChanged = e => setNicknameInput(e.target.value);
    const imageInputChanged = ev => {
        if (ev.target.files && ev.target.files[0]) {
            setImageInput(ev.target.files[0]);

            let reader = new FileReader();
            reader.onload = e => {
                setImageForDisplay(e.target.result);
            }
            reader.readAsDataURL(ev.target.files[0]);
        }
    }

    useEffect(() => {
        getMemberDetail(sessionStorage.getItem("memberUUID"), (responseData) => {
            setMemberData(responseData);
            setNameInput(responseData.name);
            setNicknameInput(responseData.nickname);
        }, () => {
            alert("상세 정보 조회 실패");
        });
    }, []);

    let infoTextArea = isNull(memberData) ? "" : isModify ?
        <div className="InfoTextArea">
            <span>{memberData.id}</span>
            <input type="text" value={nameInput} onChange={nameInputChanged}/>
            <input type="text" value={nicknameInput} onChange={nicknameInputChanged}/>
        </div> :
        <div className="InfoTextArea">
            <span>{memberData.id}</span>
            <span>{memberData.name}</span>
            <span>{memberData.nickname}</span>
        </div>

    let buttonArea = isNull(memberData) ? "" : isModify ? (
        <div className="ButtonArea">
            <button className="OK" onClick={() => {
                if (!window.confirm("정말 수정하시겠습니까?")) return;

                modifyMember({
                    uuid: memberData.uuid,
                    name: nameInput,
                    nickname: nicknameInput
                }, imageInput);
            }}>확인
            </button>
            <button className="Cancel" onClick={() => {
                setImageForDisplay(memberData.imageUrl);
                setIsModify(false);
            }}>취소
            </button>
        </div>
    ) : (
        <div className="ButtonArea">
            <button className="Modify" onClick={() => {
                setIsModify(true);
            }}>수정
            </button>
        </div>
    );

    let content = isNull(memberData) ? "" :
        <React.Fragment>
            <div className="MemberInfoArea">
                <div className="CircleImageArea">
                    <input type="file" style={{display: "none"}} id="profileImgInput" onChange={imageInputChanged}
                           accept="image/*"/>
                    <CircleImage
                        imgSrc={isNull(imageForDisplay) ? `${memberData.imageUrl}?${new Date().getTime()}` : imageForDisplay}
                        name={memberData.nickname} width='100%' onMouseOver={e => {
                        if (isModify) {
                            e.target.style.cursor = "pointer";
                        } else {
                            e.target.style.cursor = "";
                        }
                    }} onClick={() => {
                        if (isModify) {
                            document.querySelector("#profileImgInput").click();
                        }
                    }}/>
                </div>
                {infoTextArea}
                {buttonArea}
            </div>
            <button className="Logout" onClick={() => {
                sessionStorage.clear();
                window.location.reload();
            }}>로그아웃
            </button>
        </React.Fragment>

    return (
        <>
            <TopBar/>
            <h1>관리자 페이지</h1>
            {content}
        </>
    );
}

export default Home;