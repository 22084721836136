import React, {useState} from "react";
import axios from "axios";
import {refreshToken} from "../../Common";

function addAccessibleInfo(accessibleInfo, onFail) {
    axios.post(
        "/admin/ip",
        accessibleInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("ip가 추가되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                addAccessibleInfo(accessibleInfo, onFail);
            });
        } else {
            onFail();
            alert("ip 추가 실패");
            console.log(error);
        }
    });
}

function AddAccessibleInfoModal() {
    let [isLoading, setIsLoading] = useState(false);
    let [nameInput, setNameInput] = useState("");
    let [ipInput, setIpInput] = useState("");

    const nameInputChanged = e => setNameInput(e.target.value);
    const ipInputChanged = e => {
        

        setIpInput(e.target.value.replace(/[^0-9.\d]/ig, ""))
    };

    return (
        <div id="AddIpModal" className="modal">

            {/* Modal content */}
            <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#AddIpModal").style.display = "none";
                    }}>&times;</span>
                <h1>ip 등록</h1>
                <div className="IpForm">
                    <div className="InputList">
                        <input type="text" placeholder="이름" value={nameInput} onChange={nameInputChanged}/>
                        <input type="text" pattern="[0-9.]" placeholder="ip 주소" value={ipInput} onChange={ipInputChanged}/>
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={event => {
                            if (isLoading) return;
                            setIsLoading(true);

                            addAccessibleInfo({
                                name: nameInput,
                                ip: ipInput
                            }, () => {
                                setIsLoading(false);
                            });
                        }}>등록
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AddAccessibleInfoModal;