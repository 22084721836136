import React, {useEffect, useState} from "react";
import axios from "axios";
import {refreshToken} from "../../Common";

function modifyAccessibleInfo(accessibleInfo, onFail) {
    axios.put(
        "/admin/ip",
        accessibleInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("ip 정보가 변경되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyAccessibleInfo(accessibleInfo, onFail);
            });
        } else {
            onFail();
            alert("ip 정보 변경 실패");
            console.log(error);
        }
    });
}

function removeAccessibleInfo(id, onFail) {
    axios.delete(
        "/admin/ip",
        {
            params: {
                id: id
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("ip 정보가 삭제되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeAccessibleInfo(id, onFail);
            });
        } else {
            onFail();
            alert("ip 정보 삭제 실패");
            console.log(error);
        }
    });
}

function ModifyAccessibleInfoModal(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [modifyNameInput, setModifyNameInput] = useState("");
    let [modifyIpInput, setModifyIpInput] = useState("");

    const modifyNameInputChanged = e => setModifyNameInput(e.target.value);
    const modifyIpInputChanged = e => {
        
        setModifyIpInput(e.target.value.replace(/[^0-9.\d]/ig, ""))
    };

    

    useEffect(() => {
        setModifyNameInput(props.accessibleInfo.name);
        setModifyIpInput(props.accessibleInfo.ipAddress);
    }, [props.accessibleInfo]);


    return (
        <div id="ModifyIpModal" className="modal">

            {/* Modal content */}
            <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#ModifyIpModal").style.display = "none";
                    }}>&times;</span>
                <h1>ip 정보 변경</h1>
                <div className="IpForm">
                    <div className="InputList">
                        <input type="text" placeholder="이름" value={modifyNameInput || ""} onChange={modifyNameInputChanged}/>
                        <input type="text" pattern="[0-9.]" placeholder="ip 주소" value={modifyIpInput || ""} onChange={modifyIpInputChanged}/>
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={event => {
                            if (isLoading || !window.confirm("정말 수정하시겠습니까?")) return;
                            setIsLoading(true);

                            modifyAccessibleInfo({
                               id: props.accessibleInfo.id,
                               name: modifyNameInput,
                               ip: modifyIpInput
                            }, () => {
                                setIsLoading(false);
                            });
                        }}>수정
                        </button>
                        <button className="Cancel" onClick={event => {
                            if(isLoading || !window.confirm("정말 삭제하시겠습니까?")) return;
                            setIsLoading(true);

                            removeAccessibleInfo(props.accessibleInfo.id, () => {
                                setIsLoading(false);
                            });
                        }}>삭제
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ModifyAccessibleInfoModal;