import React, {useEffect, useRef, useState} from "react";
import "../Chat.scss"
import ChattingRoomListItem from "../ChattingRoomListItem";
import TopBar from "../../menu/TopBar";
import axios from "axios";
import {isNull, refreshToken} from "../../../Common";
import QueryString from "qs";
import PrevChattingView from "./PrevChattingView";

const ChatScreenMode = Object.freeze({
    FIRST_SCREEN: Symbol(0),
    ON_CHAT: Symbol(1)
});

function getPrevChattingRooms(keyword, onLoad, onFail) {
    let params = {
        memberUUID: sessionStorage.getItem("memberUUID")
    }
    if (!isNull(keyword)) params.keyword = keyword

    axios.get(
        "/admin/chatting/chattingRoom/prev",
        {
            params: params,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getPrevChattingRooms(keyword, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function PrevChattingMain(props) {

    const params = QueryString.parse(props.location.search.replace('?', ''));

    let [mode, setMode] = useState(ChatScreenMode.FIRST_SCREEN);
    let [keyword, setKeyword] = useState("");
    let [chattingRoomInfo, setChattingRoomInfo] = useState();
    let [chattingRoomData, setChattingRoomData] = useState([]);

    const keywordChanged = e => setKeyword(e.target.value);

    useEffect(() => {
        getPrevChattingRooms(null, (responseData) => {
            setChattingRoomData(responseData.sort((a, b) => {
                if (a.lastMessageCreatedDate > b.lastMessageCreatedDate) return -1
                if (a.lastMessageCreatedDate < b.lastMessageCreatedDate) return 1

                if (a.chattingRoomTitle > b.chattingRoomTitle) return -1
                else return 1
            }));

            if (!isNull(params.chattingRoomId)) {
                const chattingRoomInfo = responseData.find(chattingRoom => chattingRoom.chattingRoomId === parseInt(params.chattingRoomId));

                setChattingRoomInfo(chattingRoomInfo);
                setMode(ChatScreenMode.ON_CHAT);
            }
        }, () => {
            alert("채널 목록 조회 실패");
        });
    }, []);

    let list = isNull(chattingRoomData) ? "" : chattingRoomData.map(data =>
        <ChattingRoomListItem
            key={data.chattingRoomId}
            chattingRoomInfo={data}
            onClick={() => {
                setChattingRoomInfo(data);
                setMode(ChatScreenMode.ON_CHAT);
            }}
        />
    );

    let content = ""
    switch (mode) {
        case ChatScreenMode.FIRST_SCREEN:
            content = (
                <React.Fragment>
                    <section id="FirstScreenView">
                        <div>
                            <h2>이전 채널 목록</h2>
                            <ul>
                                <li>자정 이전의 메시지들을 확인할 수 있습니다.</li>
                                <li>비활성화된 채널을 확인하고, 활성화 상태로 변경할 수 있습니다.</li>
                                <li>삭제된 채널을 확인할 수 있습니다.</li>
                            </ul>
                        </div>
                    </section>
                </React.Fragment>
            );

            break;
        case ChatScreenMode.ON_CHAT:
            content = <PrevChattingView chattingRoomInfo={chattingRoomInfo} onClose={() => {
                setMode(ChatScreenMode.FIRST_SCREEN);
            }}/>

            break;
    }

    return (
        <>
            <TopBar/>
            <div id="ChatScreen">
                <section id="ChannelList">
                    <div id="ChannelSearch">
                        <div id="SearchBox">
                            <input id="SearchBar" type="text" placeholder="이름 검색"
                                   style={{backgroundImage: `url('/images/search.png')`}}
                                   value={keyword} onChange={keywordChanged}
                                   onKeyUp={(e) => {
                                       if (e.keyCode === 13) { //enter
                                           getPrevChattingRooms(keyword, (responseData) => {
                                               setChattingRoomData(responseData);
                                           }, () => {
                                               alert("채널 목록 조회 실패");
                                           });
                                       }
                                   }}
                            />
                        </div>
                    </div>

                    <div id="Items">
                        {list}
                    </div>
                </section>

                {content}
            </div>
        </>
    );
}

export default PrevChattingMain;