import React, {useEffect, useState} from "react";
import CircleImage from "../common/CircleImage";
import axios from "axios";
import {isNull, refreshToken} from "../../Common";

function modifyMember(modifyInfo, image, onFail) {
    let data = new FormData();
    for (const [key, value] of Object.entries(modifyInfo)) {
        data.append(key, value);
    }
    data.append("image", image);

    axios.put(
        "/admin/member",
        data,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken"),
                "Content-Type": "multipart/form-data"
            }
        }
    ).then(() => {
        alert("수정되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        console.log(error.response.data);
        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyMember(modifyInfo, image, onFail);
            });
        } else if (code === 603) {
            onFail();
            alert("닉네임 중복");
        } else {
            onFail();
            alert("수정 실패");
            console.log(error);
        }
    });
}

function removeGroupMember(adminGroupId, uuid, onLoad, onFail) {
    axios.delete(
        "/admin/member",
        {
            params: {
                uuid: uuid
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("삭제되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeMember(adminGroupId, uuid, onLoad, onFail)
            });
        } else {
            alert("삭제 실패");
            console.log(error);
        }
    });
}

function removeChattingMember(removeInfo, onSuccess, onFail) {
    axios.delete(
        "/admin/chatting/chattingRoom/member",
        {
            params: removeInfo,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeChattingMember(removeInfo, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function removeMember(uuid, onFail) {
    axios.delete(
        "/admin/member",
        {
            params: {
                uuid: uuid
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("삭제되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeMember(uuid, onFail);
            });
        } else {
            onFail();
            alert("삭제 실패");
            console.log(error);
        }
    });
}

function AdminMemberDetail(props) {
    let [isLoading] = useState(false);
    let [isModify, setIsModify] = useState(false);
    let [nameInput, setNameInput] = useState(props.memberInfo.name);
    let [nicknameInput, setNicknameInput] = useState(props.memberInfo.nickname);
    let [imageInput, setImageInput] = useState("");
    let [imageForDisplay, setImageForDisplay] = useState(props.memberInfo.imageUrl);

    const nameInputChanged = e => setNameInput(e.target.value);
    const nicknameInputChanged = e => setNicknameInput(e.target.value);
    const imageInputChanged = ev => {
        if (ev.target.files && ev.target.files[0]) {
            setImageInput(ev.target.files[0]);

            let reader = new FileReader();
            reader.onload = e => {
                setImageForDisplay(e.target.result);
            }
            reader.readAsDataURL(ev.target.files[0]);
        }
    }

    useEffect(() => {
        setNameInput(props.memberInfo.name);
        setNicknameInput(props.memberInfo.nickname);
    }, [props.memberInfo]);

    let infoTextArea = isModify ? (
        <div className="InfoTextArea">
            <span>{props.memberInfo.id}</span>
            <input type="text" value={nameInput} onChange={nameInputChanged}/>
            <input type="text" value={nicknameInput} onChange={nicknameInputChanged}/>
        </div>
    ) : (
        <div className="InfoTextArea">
            <span>{props.memberInfo.id}</span>
            <span>{nameInput}</span>
            <span>{nicknameInput}</span>
        </div>
    );


    let chattingRoomList = isNull(props.memberInfo) || isNull(props.memberInfo.chattingRooms) ? <tr>
        <td colSpan={3}>아직 참여한 채팅이 없습니다.</td>
    </tr> : props.memberInfo.chattingRooms.map(data => {
        let chattingRoomType = "";
        switch (data.chattingRoomType) {
            case "PUBLIC":
                chattingRoomType = "공개";
                break;
            case "PRIVATE":
                chattingRoomType = "비공개";
                break;
            case "DUMMY":
                chattingRoomType = "더미";
                break;
            default:
        }
        let chattingRoomStatus = "";
        switch (data.chattingRoomStatus) {
            case "ACTIVITY":
                chattingRoomStatus = "활동";
                break;
            case "INACTIVITY":
                chattingRoomStatus = "비활동";
                break;
            case "REMOVE":
                chattingRoomStatus = "삭제";
                break;
            default:
        }

        return <tr key={data.chattingRoomId}>
            <td>{data.chattingRoomTitle}</td>
            <td>{`${chattingRoomType} / ${chattingRoomStatus} / ${data.chattingRoomMemberCount}명`}</td>
            <td>
                <button className="remove" onClick={() => {
                    if (isLoading || !window.confirm(`'${props.memberInfo.nickname}' 회원을 채팅방에서 퇴장시키시겠습니까?`)) return

                    removeChattingMember({
                        chattingRoomId: data.chattingRoomId,
                        memberUUID: props.memberInfo.uuid
                    }, () => {
                        window.location.reload();
                    }, () => {
                        alert("퇴출 실패");
                    });
                }}>퇴장
                </button>
            </td>
        </tr>
    });

    let managerGroupArea = props.memberInfo.managerGroupId == null ? (
            <div>
                <span>참여된 그룹이 없습니다.</span>
            </div>
        ) :
        (
            <div className="ChattingRoomList">
                <table>
                    <tbody>
                    <tr>
                        <td>그룹 아이디</td>
                        <td>{props.memberInfo.managerGroupId}</td>
                        {/*<td>*/}
                        {/*    <button className="remove" onClick={() => {*/}
                        {/*        if (!window.confirm(`'${props.memberInfo.nickname}' 회원을 그룹에서 퇴장시키시겠습니까?`)) return*/}
                        {/*        console.log('adminGroupId' + props.memberInfo.managerGroupId)*/}
                        {/*        console.log('uuid'+ props.memberInfo.uuid)*/}
                        {/*        removeGroupMember({*/}
                        {/*            adminGroupId: props.memberInfo.managerGroupId,*/}
                        {/*            uuid: props.memberInfo.uuid*/}
                        {/*        }, () => {*/}
                        {/*            window.location.reload();*/}
                        {/*        }, () => {*/}
                        {/*            alert("퇴출 실패");*/}
                        {/*        });*/}
                        {/*    }}>퇴장*/}
                        {/*    </button>*/}
                        {/*</td>*/}
                    </tr>
                    <tr>
                        <td>그룹 이름</td>
                        <td>{props.memberInfo.managerGroupName}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )

    let buttonArea = isModify ? (
        <div className="ButtonArea">
            <button className="OK" onClick={() => {
                if (isLoading || !window.confirm("정말 수정하시겠습니까?")) return;

                modifyMember({
                    uuid: props.memberInfo.uuid,
                    name: nameInput,
                    nickname: nicknameInput
                }, imageInput, () => {
                })
            }}>확인
            </button>
            <button className="Cancel" onClick={() => {
                setNameInput(props.memberInfo.name);
                setNicknameInput(props.memberInfo.nickname);
                setImageForDisplay(props.memberInfo.imageUrl);
                setIsModify(false);
            }}>취소
            </button>
        </div>
    ) : (
        <div className="ButtonArea">
            <button className="Modify" onClick={() => {
                setIsModify(true);
            }}>수정
            </button>
            <button className="Remove" onClick={() => {
                if (isLoading || !window.confirm(`계정을 삭제하면 해당 계정으로 로그인할 수 없습니다.\n'${props.memberInfo.id}'계정을 삭제하시겠습니까?`)) return;
                if (props.memberInfo.id === "admin") {
                    alert("최고 관리자 계정은 삭제할 수 없습니다.");
                    return;
                }

                removeMember(props.memberInfo.uuid, () => {
                });
            }}>삭제
            </button>
        </div>
    );

    return (
        <div className="MemberDetailContainer">
            <div className="MemberInfoArea">
                <div className="CircleImageArea">
                    <input type="file" style={{display: "none"}} id="profileImgInput" onChange={imageInputChanged}
                           accept="image/*"/>
                    <CircleImage imgSrc={isNull(imageForDisplay) ? props.memberInfo.imageUrl : imageForDisplay}
                                 name={props.memberInfo.name} width='100%' onMouseOver={e => {
                        if (isModify) {
                            e.target.style.cursor = "pointer";
                        } else {
                            e.target.style.cursor = "";
                        }
                    }} onClick={() => {
                        if (isModify) {
                            document.querySelector("#profileImgInput").click();
                        }
                    }}/>
                </div>
                {infoTextArea}
                {buttonArea}
            </div>

            <h2>참여 그룹</h2>
            {managerGroupArea}
            <br/><br/>
            <h2>참여 채팅 리스트</h2>
            <div className="ChattingRoomList">
                <table>
                    <tbody>
                    {chattingRoomList}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default AdminMemberDetail;