import React, {useEffect, useState} from "react";
import TopBar from "../menu/TopBar";
import {Link} from "react-router-dom";
import axios from "axios";
import "./AccessibleInfoList.scss";
import ModifyAccessibleInfoModal from "./ModifyAccessibleInfoModal";
import AddAccessibleInfoModal from "./AddAccessibleInfoModal";
import {isNull, refreshToken} from "../../Common";

function getAccessibleInfoList(pageNum, onLoad, onFail) {
    axios.get(
        "/admin/ip/list",
        {
            params: {
                page: pageNum
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getAccessibleInfoList(pageNum, onLoad, onFail);
            });
        } else {
            onFail(error);
        }
    });
}

function AccessibleInfoList(props) {
    let pageNum = parseInt(props.match.params.pageNumber) - 1
    let [accessibleInfoData, setAccessibleInfoData] = useState("");
    let [accessibleInfoForModify, setAccessibleInfoForModify] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        getAccessibleInfoList(pageNum, (responseData) => {
            setAccessibleInfoData(responseData);
        }, (error) => {
            alert("ip 추가 실패");
            console.log(error);
        });

    }, [props.match.params.pageNumber]);

    let trList = isNull(accessibleInfoData) || isNull(accessibleInfoData.content) ? <tr><td colSpan={4}></td></tr> : accessibleInfoData.content.map((data) =>
        <tr key={data.id} onClick={event => {
            setAccessibleInfoForModify(data);
            document.querySelector("#ModifyIpModal").style.display = "block";
        }}>
            <td>{data.name}</td>
            <td>{data.ipAddress}</td>
        </tr>
    );

    let minPage = pageNum - (pageNum % 10) + 1
    let maxPage = pageNum - (pageNum % 10) + 10
    if (maxPage > accessibleInfoData.totalPages) {
        maxPage = accessibleInfoData.totalPages
    }

    let pageList = []
    for (let i = minPage; i <= maxPage; i++) {
        pageList.push(
            <Link to={`/ip/list/${i}`} key={i}>
                {i}
            </Link>
        );
    }
    if (minPage > 1) {
        pageList.unshift(
            <Link to={`/ip/list/${minPage - 1}`} key={'prev'}>
                이전
            </Link>
        )
    }
    if (maxPage < accessibleInfoData.totalPages) {
        pageList.push(
            <Link to={`/ip/list/${maxPage + 1}`} key={'next'}>
                다음
            </Link>
        )
    }

    window.onclick = ev => {
        let addModal = document.querySelector("#AddIpModal");
        let modifyModal = document.querySelector("#ModifyIpModal");
        if (ev.target === addModal) {
            addModal.style.display = "none";
        }
        if (ev.target === modifyModal) {
            modifyModal.style.display = "none";
        }
    }

    return (
        <>
            <TopBar/>
            <div className="AccessibleInfoListContainer">
                <div className="TableContainer">
                    <table>
                        <thead>
                        <tr>
                            <th>이름</th>
                            <th>ip주소</th>
                        </tr>
                        </thead>
                        <tbody>
                        {trList}
                        </tbody>
                    </table>
                    <div className="ButtonList">
                        <button onClick={event => {
                            document.querySelector("#AddIpModal").style.display = "block";
                        }}>IP 등록</button>
                        <div className="PageList">
                            {pageList}
                        </div>
                    </div>
                </div>
            </div>

            <AddAccessibleInfoModal />

            <ModifyAccessibleInfoModal accessibleInfo={accessibleInfoForModify} />
        </>
    );
}

export default AccessibleInfoList;