import logo from './logo.svg';
import './App.css';
import {Route} from "react-router-dom";
import Home from "./components/Home";
import MemberList from "./components/member/MemberList";
import DummyMemberList from "./components/member/DummyMemberList";
import AdminMemberList from "./components/member/AdminMemberList";
import ChattingMain from "./components/chat/ChattingMain";
import PrevChattingMain from "./components/chat/prev/PrevChattingMain";
import Login from "./components/login/Login";
import AccessibleInfoList from "./components/ip/AccessibleInfoList";
import MacroList from "./components/macro/MacroList";
import AdminGroupList from "./components/member/AdminGroupList";
import ReportList from "./components/chat/ReportList";

function App() {
    console.log(process.env.REACT_APP_WEBSOCKET_URL)
    return (
        <div>
            <Route exact path="/" component={Home}/>
            <Route path="/member/list/GENERAL/:pageNumber" component={MemberList}/>
            <Route path="/member/list/DUMMY/:pageNumber" component={DummyMemberList}/>
            <Route path="/member/list/MANAGER/:pageNumber" component={AdminMemberList}/>
            <Route path="/macro/list/:pageNumber" component={MacroList}/>
            <Route path="/chat" component={ChattingMain}/>
            <Route path="/prev/chat" component={PrevChattingMain}/>
            <Route path="/login" component={Login}/>
            <Route path="/ip/list/:pageNumber" component={AccessibleInfoList}/>
            <Route path="/manager/group/list" component={AdminGroupList}/>
            <Route path="/report/list/:pageNumber" component={ReportList}/>
        </div>
    );
}

export default App;
