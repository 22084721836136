import axios from "axios";
import moment from "moment";

export function refreshToken(callback) {
    axios.post(
        "/token/refresh",
        {
            uuid: sessionStorage.getItem("memberUUID")
        }, {
            headers: {
                Authorization: sessionStorage.getItem("refreshToken")
            }
        }
    ).then(response => {
        const tokenInfo = response.data;
        sessionStorage.setItem("accessToken", tokenInfo.accessToken);
        if (!isNull(tokenInfo.refreshToken)) {
            sessionStorage.setItem("refreshToken", tokenInfo.refreshToken);
        }
        callback();
    }).catch(error => {
        alert("refresh token failed");
        console.log(error);
        sessionStorage.clear();
        window.location.href = "/login";
    });
}

export function isNull(value) {
    return value === undefined || value === null || value.length === 0;
}

export function lastReceivedTimeString(targetDate) {
    if (isNull(targetDate)) return "";

    const todayMoment = moment(new Date());
    const targetDateMoment = moment(targetDate);

    if (todayMoment.diff(targetDateMoment, "days") >= 1) {
        if (todayMoment.dayOfYear() - targetDateMoment.dayOfYear() > 7) {
            return targetDate.format("yyyy. MM. dd");
        }
        if (todayMoment.dayOfYear() - targetDateMoment.dayOfYear() >= 1) {
            return `${todayMoment.dayOfYear() - targetDateMoment.dayOfYear()}일 전`;
        }
    }
    if (todayMoment.diff(targetDateMoment, "hours") >= 1) {
        return `${todayMoment.diff(targetDateMoment, "hours")}시간 전`;
    }
    if (todayMoment.diff(targetDateMoment, "minutes") >= 1) {
        return `${todayMoment.diff(targetDateMoment, "minutes")}분 전`;
    }

    return `${todayMoment.diff(targetDateMoment, "seconds")}초 전`;
}

export function messageCreatedDate(targetDate) {
    const targetDateMoment = moment(targetDate);

    return targetDateMoment.format("HH:mm");
}

export function sleep(ms) {
    const wakeUpTime = Date.now() + ms;
    while (Date.now() < wakeUpTime) {}
}