import React, {useEffect, useState} from "react";
import axios from "axios";
import {refreshToken} from "../../Common";

function modifyMacro(macro, onFail) {
    axios.put(
        "/admin/macro",
        macro,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("매크로가 변경되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyMacro(macro, onFail);
            });
        } else {
            onFail();
            alert("매크로 변경 실패");
            console.log(error);
        }
    });
}

function removeMacro(id, onFail) {
    axios.delete(
        "/admin/macro",
        {
            params: {
                id: id
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("매크로가 삭제되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeMacro(id, onFail);
            });
        } else {
            onFail();
            alert("매크로 삭제 실패");
            console.log(error);
        }
    });
}

function ModifyMacroModal(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [modifyTextInput, setModifyTextInput] = useState("");

    const modifyTextInputChanged = e => setModifyTextInput(e.target.value);

    useEffect(() => {
        setModifyTextInput(props.macro.text);
    }, [props.macro]);


    return (
        <div id="ModifyMacroModal" className="modal">

            {/* Modal content */}
            <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#ModifyMacroModal").style.display = "none";
                    }}>&times;</span>
                <h1>매크로 변경</h1>
                <div className="MacroForm">
                    <div className="InputList">
                        <input type="text" placeholder="매크로 텍스트 입력" value={modifyTextInput || ""} onChange={modifyTextInputChanged}/>
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={event => {
                            if(isLoading || !window.confirm("정말 수정하시겠습니까?")) return;
                            setIsLoading(true);

                            modifyMacro({
                                id: props.macro.id,
                                text: modifyTextInput
                            }, () => {
                                setIsLoading(false);
                            });
                        }}>수정
                        </button>
                        <button className="Cancel" onClick={event => {
                            if(isLoading || !window.confirm("이 매크로를 등록한 모든 더미에게서 삭제됩니다. 정말 삭제하시겠습니까?")) return;
                            setIsLoading(true);

                            removeMacro(props.macro.id, () => {
                                setIsLoading(false);
                            });
                        }}>삭제
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ModifyMacroModal;