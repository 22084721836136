import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import "./TopBar.scss"

function TopBar(props) {

    useEffect(() => {
        let accessToken = sessionStorage.getItem("accessToken")
        if (accessToken === null || accessToken === undefined) {
            window.location.href = "/login";
        }
    }, []);

    const memberType = sessionStorage.getItem("memberType");
    let content = memberType === "ADMINISTRATOR" ? <React.Fragment>
        <div className="TopBar">
            <Link to="/">
                <button>홈</button>
            </Link>
            {/*<a style={props.isHome ? {display: "none"} : {}}>*/}
            {/*    <button onClick={(e) => {window.history.back();}}>뒤로</button>*/}
            {/*</a>*/}
            <Link to="/member/list/MANAGER/1">
                <button>관리자 계정 관리</button>
            </Link>
            <Link to="/manager/group/list">
                <button>관리자 그룹 관리</button>
            </Link>
        </div>
    </React.Fragment> : <React.Fragment>
        <div className="TopBar">
            <Link to="/">
                <button>홈</button>
            </Link>
            {/*<a style={props.isHome ? {display: "none"} : {}}>*/}
            {/*    <button onClick={(e) => {window.history.back();}}>뒤로</button>*/}
            {/*</a>*/}
            <Link to="/member/list/GENERAL/1">
                <button>일반 회원 관리</button>
            </Link>
            <Link to="/member/list/DUMMY/1">
                <button>더미 회원 관리</button>
            </Link>
            <Link to="/macro/list/1">
                <button>매크로 관리</button>
            </Link>
            <Link to="/chat">
                <button>채팅</button>
            </Link>
            <Link to="/ip/list/1">
                <button>ip 관리</button>
            </Link>
            <Link to="/prev/chat">
                <button>이전 채팅 목록</button>
            </Link>
            <Link to="/report/list/1">
                <button>신고 내역</button>
            </Link>
        </div>
    </React.Fragment>

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
}

export default TopBar;