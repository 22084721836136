import React from "react";
import CircleImage from "../../common/CircleImage";
import {isNull, messageCreatedDate} from "../../../Common";

function ChatMessage(props) {

    const isMe = () => {
        return props.messageInfo.sendMemberUUID === sessionStorage.getItem("memberUUID");
    }

    let content = "";
    switch (props.messageInfo.messageType) {
        case "GENERAL":
            content = <p className="Text"
                         data-message-id={props.messageInfo.sendMessageId}
                         dangerouslySetInnerHTML={{
                             __html: isNull(props.searchKeyword) ? props.messageInfo.content :
                                 props.messageInfo.content.replaceAll(props.searchKeyword, "<mark>" + props.searchKeyword + "</mark>")
                         }}/>;
            break;
        case "FILE":
            const fileType = props.messageInfo.fileMetaData.fileType;
            let replyInfo = "";
            if (!isNull(props.messageInfo.replyMessageId)) {
                const targetMember = props.getMemberInfo(props.messageInfo.replyMessageCreatedMemberUUID);

                replyInfo = isNull(targetMember) ? "" : <div className="ReplyInfo">
                    <span>{`'${isNull(targetMember) ? "" : targetMember.memberNickname + "(" + targetMember.name + ")"}'님에게 답장`}</span>
                    <span>{props.targetContent}</span>
                </div>;
            }

            if (fileType.includes("image")) {
                let w = props.messageInfo.fileMetaData.imageRatio.split(':')[0]
                let h = props.messageInfo.fileMetaData.imageRatio.split(':')[1]
                while (w > 512 || h > 512) {
                    w /= 2
                    h /= 2
                }
                content = <p className="Text" data-message-id={props.messageInfo.sendMessageId}>
                    {replyInfo}
                    <img
                        style={{aspectRatio: w / h}} src={props.messageInfo.fileMetaData.fileUrl}/><br/>
                    <a href={props.messageInfo.fileMetaData.fileUrl}
                       dangerouslySetInnerHTML={{
                           __html: isNull(props.searchKeyword) ? props.messageInfo.fileMetaData.fileName :
                               props.messageInfo.fileMetaData.fileName.replaceAll(props.searchKeyword, "<mark>" + props.searchKeyword + "</mark>")
                       }}/>
                </p>;
            } else {
                content = <p className="Text" data-message-id={props.messageInfo.sendMessageId}>
                    {replyInfo}
                    <a href={props.messageInfo.fileMetaData.fileUrl}
                       dangerouslySetInnerHTML={{
                           __html: isNull(props.searchKeyword) ? props.messageInfo.fileMetaData.fileName :
                               props.messageInfo.fileMetaData.fileName.replaceAll(props.searchKeyword, "<mark>" + props.searchKeyword + "</mark>")
                       }}/> <br/>
                    <span>{`${parseInt(props.messageInfo.fileMetaData.fileSize) / 1000}KB`}</span>
                </p>;
            }
            break;
        case "REPLY":
            const targetMember = props.getMemberInfo(props.messageInfo.replyMessageCreatedMemberUUID);
            /** 답장 없어지는거 수정 - 테스트 필요 **/
            content = isNull(props.messageInfo) ? "" :
                <p className="Text" data-message-id={props.messageInfo.sendMessageId}>
                    <span className="ReplyInfo">
                        <span>{`'${isNull(targetMember) ? "" : targetMember.memberNickname}'님에게 답장`}</span>
                        <span>{props.targetContent}</span>
                    </span>
                    <span dangerouslySetInnerHTML={{
                        __html: isNull(props.searchKeyword) ? props.messageInfo.content :
                            props.messageInfo.content.replaceAll(props.searchKeyword, "<mark>" + props.searchKeyword + "</mark>")
                    }}/>
                </p>;
            break;
        default:
    }

    return (
        <>
            <div className="ChatMessage" style={isMe() ? {flexDirection: 'row-reverse'} : null}>
                <CircleImage
                    imgSrc={`https://d2ljopxexv96h.cloudfront.net/member_profile_image/${props.messageInfo.sendMemberUUID}.jpeg`}
                    width='5%'/>
                <div className="ContentArea">
                    <div className="Name" style={isMe() ? {justifyContent: 'right'} : null}>
                        {props.nickname}
                    </div>
                    <div className="ContentBox" style={isMe() ? {flexDirection: 'row-reverse'} : null}>
                        <div className="TextBox" style={{
                            // backgroundColor: isMe() ? '#fae102' : isManager() ? '#f5b295' : '#eae7e6', TODO: 관리자 메시지 따료 표시해야하면 주석 해제
                            backgroundColor: isMe() ? '#fae102' : '#eae7e6',
                            borderRadius: isMe() ? '20px 0 20px 20px' : '0 20px 20px 20px',
                        }}>
                            {content}
                        </div>
                        <div className="TimeBox">
                            {messageCreatedDate(props.messageInfo.createdDate)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChatMessage;