import React from "react";
import CircleImage from "../common/CircleImage";
import {isNull, lastReceivedTimeString} from "../../Common";

function ChattingRoomListItem(props) {

    let badge = props.chattingRoomInfo.unreadMessageCount === 0 ? "" : <mark>{props.chattingRoomInfo.unreadMessageCount}</mark>;

    function countPlus() {
        props.chattingRoomInfo.unreadMessageCount++
    }

    return (
        <div className="ChannelListItem" onClick={props.onClick}>
            <CircleImage imgSrc={props.chattingRoomInfo.chattingRoomImageUrl} width='20%'/>

            <div className="ContentArea">
                <div className="NameAndTimeArea">
                    <span className="name">{props.chattingRoomInfo.chattingRoomTitle}</span>
                    <div className="Spacer"/>
                    <span className="time">{lastReceivedTimeString(isNull(props.chattingRoomInfo.lastMessageCreatedDate) ? props.chattingRoomInfo.chattingRoomCreatedDate : props.chattingRoomInfo.lastMessageCreatedDate)}</span>
                </div>
                <div className="LastMessageArea">
                    {props.chattingRoomInfo.lastMessageContent}
                </div>
                <div className="BadgeArea">
                    {badge}
                </div>
            </div>
        </div>
    );
}

export default ChattingRoomListItem;