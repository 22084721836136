import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "./Chat.scss";
import CircleImage from "../common/CircleImage";
import ChatMessage from "./ChatMessage";
import * as StompJS from "@stomp/stompjs";
import {isNull, refreshToken} from "../../Common";
import axios from "axios";
import imageCompression from "browser-image-compression";
import {Virtuoso} from "react-virtuoso";

/**
 * 현재 채팅방에 참여 가능한 회원 목록
 * @param requestInfo
 * {
 *     chattingRoomId: 채팅방 id,
 *     keyword: 검색어,
 *     memberType: 회원 타입 (일반 회원, 관리자, 더미)
 * }
 * @param onLoad 목록 조회 콜백
 * @param onFail 오류 콜백
 */
function getMembers(requestInfo, onLoad, onFail) {
    axios.get(
        "/admin/member/list/chat/available",
        {
            params: requestInfo,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMembers(requestInfo, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}


function postChattingRoomImage(chattingRoomId, image, onSuccess, onFail) {
    let data = new FormData();
    data.append("chattingRoomId", chattingRoomId);
    data.append("file", image);

    axios.post(
        "/admin/chatting/createChattingRoom/image",
        data,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                postChattingRoomImage(chattingRoomId, image, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}


function getChattingRoomInitData(chattingRoomId, onLoad, onFail) {
    axios.get(
        "/chatting/chattingRoom/initData",
        {
            params: {
                chattingRoomId: chattingRoomId,
                memberUUID: sessionStorage.getItem("memberUUID")
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        console.log('error', error)
        let code = error.response.data && error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getChattingRoomInitData(chattingRoomId, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function getMessages(chattingRoomId, lastMessageCreatedDate, onLoad, onFail) {
    axios.get(
        "/chatting/chattingRoom/messages",
        {
            params: {
                chattingRoomId: chattingRoomId,
                lastMessageCreatedDate: lastMessageCreatedDate,
                memberUUID: sessionStorage.getItem("memberUUID")
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMessages(chattingRoomId, lastMessageCreatedDate, onLoad, onFail)
            });
        } else {
            console.log(error);
            onFail();
        }
    })
}

function getMessagesByKeyword(chattingRoomId, keyword, lastMessageCreatedDate, onLoad, onFail) {
    axios.get(
        "/chatting/chattingRoom/message/search",
        {
            params: {
                keyword: keyword,
                chattingRoomId: chattingRoomId,
                lastMessageCreatedDate: lastMessageCreatedDate,
                memberUUID: sessionStorage.getItem("memberUUID")
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMessagesByKeyword(chattingRoomId, keyword, lastMessageCreatedDate, onLoad, onFail)
            });
        } else {
            console.log(error);
            onFail();
        }
    })
}

async function sendFileMessage(chattingRoomInfo, memberUUID, file, replyInfo, isForceMessage, onSuccess, onFail) {
    let data = new FormData();

    data.append("chattingRoomId", chattingRoomInfo.chattingRoomId);
    data.append("chattingRoomType", chattingRoomInfo.chattingRoomType);
    data.append("memberUUID", memberUUID);
    data.append("sendMemberType", "MANAGER");
    data.append("isForceMessage", isForceMessage);

    if (!isNull(replyInfo)) {
        data.append("replyMessageId", replyInfo.sendMessageId);
        data.append("targetMemberUUID", replyInfo.sendMemberUUID);
    }

    if (file.type.includes("image")) {
        const options = {
            maxSizeMB: 2
        }

        const compressed = await imageCompression(file, options);
        const compressedFile = new File([compressed], compressed.name, {type: compressed.type});
        data.append("file", compressedFile);
        const img = new Image();
        img.onload = () => {
            data.append("imageWidth", img.naturalWidth);
            data.append("imageHeight", img.naturalHeight);

            sendFileRequest(data, onSuccess, onFail);
        }
        let reader = new FileReader();
        reader.onload = (e) => {
            img.src = e.target.result;
        }
        reader.readAsDataURL(compressedFile);

    } else {
        data.append("file", file);
        sendFileRequest(data, onSuccess, onFail);
    }
}

function sendFileRequest(data, onSuccess, onFail) {
    axios.post(
        "/chatting/chattingRoom/fileMessage",
        data,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                sendFileRequest(data, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function setInform(data, onFail) {
    axios.post(
        "/admin/chatting/createInformMessage",
        null,
        {
            params: data,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("공지 메시지로 설정되었습니다.");
        window.location.href = `/chat?chattingRoomId=${data.chattingRoomId}`
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                setInform(data, onFail)
            });
        } else {
            onFail();
            console.log(error);
            alert("공지 메시지 설정 실패");
        }
    });
}

function removeInform(data, onFail) {
    axios.delete(
        "/admin/chatting/informMessage",
        {
            params: {
                messageId: data.messageId
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("공지 메시지가 제거되었습니다.");
        window.location.href = `/chat?chattingRoomId=${data.chattingRoomId}`
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeInform(data, onFail)
            });
        } else {
            onFail();
            console.log(error);
            alert("공지 메시지 제거 실패");
        }
    });
}

function modifyChattingRoomStatus(modifyInfo, onFail) {
    axios.put(
        "/admin/chatting/chattingRoom/status",
        modifyInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("채팅방 상태가 변경되었습니다. 목록을 확인하세요.");
        window.location.href = "/chat";
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyChattingRoomStatus(modifyInfo, onFail);
            });
        } else {
            onFail();
            console.log(error);
            alert("채팅방 상태 변경 실패");
        }
    });
}

function removeChattingRoom(chattingRoomId, onFail) {
    axios.delete(
        "/admin/chatting/chattingRoom",
        {
            params: {
                chattingRoomId: chattingRoomId
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("채팅방이 삭제되었습니다. 목록을 확인하세요.");
        window.location.href = "/chat";
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeChattingRoom(chattingRoomId, onFail);
            });
        } else {
            onFail();
            console.log(error);
            alert("채팅방 삭제 실패");
        }
    });
}

function addChattingMembers(addInfo, onSuccess, onFail) {
    axios.post(
        "/admin/chatting/invite",
        addInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                addChattingMembers(addInfo, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function removeChattingMember(removeInfo, onSuccess, onFail) {
    axios.delete(
        "/admin/chatting/chattingRoom/member",
        {
            params: removeInfo,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeChattingMember(removeInfo, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function ChattingView(props) {
    const chattingRoomInfo = props.chattingRoomInfo;
    const client = useRef({});
    const messagesRef = useRef(null);
    const messagesEndRef = useRef(null);

    let [messageInput, setMessageInput] = useState("");
    let [fileInput, setFileInput] = useState();
    let [isForceMessage, setIsForceMessage] = useState(false);
    let [messages, setMessages] = useState([]);
    let [firstItemIndex, setFirstItemIndex] = useState(0);
    let [messageKeyword, setMessageKeyword] = useState("");
    let [searchedIndex, setSearchedIndex] = useState(-1);
    let [informMessage, setInformMessage] = useState();
    let [contextMenuMessage, setContextMenuMessage] = useState();
    let [replyInfo, setReplyInfo] = useState();
    let [isMessageLoading, setIsMessageLoading] = useState(false);
    let [isLast, setIsLast] = useState(false);
    let [isRequestProcessing, setIsRequestProcessing] = useState(false);
    let [availableMembers, setAvailableMembers] = useState([]);
    let [currentMembers, setCurrentMembers] = useState([]);

    const scrollToBottom = () => {
        console.log("scrollToBottom")
        messagesEndRef && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }

      useEffect(()=> {
        // scrollToBottom();
      }, [])

    const disconnect = () => {
        setCurrentMembers([]);
        setMessages([]);
        setInformMessage(null);
        setIsMessageLoading(false);
        setIsLast(false);
        setMessageInput("");
        setIsForceMessage(false);
        setFileInput(null);
        setMessageKeyword("");
        setSearchedIndex(-1);
        setReplyInfo(null);
        setIsRequestProcessing(false);
        setAvailableMembers([]);


        sessionStorage.setItem('selectChattingRoomId', -1)
        //채팅 연결 종료 메시지
        client.current.publish({
            destination: `/topic/chatting/pub/disconnect/${chattingRoomInfo.chattingRoomId}`,
            body: sessionStorage.getItem("memberUUID")
        });

        client.current.deactivate();
    }

    useEffect(() => {
        console.log("props.chattingRoomInfo", props.chattingRoomInfo)
        if (isMessageLoading) return;

        setIsMessageLoading(true);
        console.log("chattingRoomInfo.chattingRoomId", chattingRoomInfo.chattingRoomId)
        getChattingRoomInitData(chattingRoomInfo.chattingRoomId,
            (responseData) => {
                setCurrentMembers(responseData.chattingRoomMembers);

                console.log("responseData.chattingRoomMessages", responseData.chattingRoomMessages)
                // createdDate
                setMessages(responseData.chattingRoomMessages
                    .sort((a,b) => {
                        return new Date(a.createdDate).getTime() - 
                            new Date(b.createdDate).getTime()
                    }) );
                scrollToBottom();
                setInformMessage(responseData.chattingRoomInform);
                setIsMessageLoading(false);
            }, () => {
                setIsMessageLoading(false);
                alert("채널 정보 조회 실패");
            });
        connect();

        return () => {
            disconnect();
        }
    }, [props.chattingRoomInfo]);

    const messageKeywordChanged = e => {
        setMessageKeyword(e.target.value);
        setSearchedIndex(-1);
    }
    const messageInputChanged = e => setMessageInput(e.target.value);
    const fileInputChanged = e => {
        if (e.target.files && e.target.files[0]) {
            setFileInput(e.target.files[0]);
            setMessageInput(`파일 전송: ${e.target.files[0].name}`);
        } else {
            setFileInput(null);
            setMessageInput("");
        }
    }
    const isForceMessageChanged = e => setIsForceMessage(!isForceMessage);
    const connect = () => {
        const brokerURL = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:8080/websocket/invest";
    
        client.current = new StompJS.Client({
            brokerURL: brokerURL,
            connectHeaders: {
                Authorization: sessionStorage.getItem("accessToken")
            },
            debug: (str) => {
                console.log(str);
                console.log("str", str);
                if (str.indexOf("Connection closed to ") > -1 ) {
                    disconnect();
                    connect();
                }
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => {
                subscribe();
            },
            onStompError: (frame) => {
                if (frame.headers.message.includes("invalid token")) {
                    refreshToken(() => {
                        client.current.deactivate()
                            .then(response => {
                                connect();
                            }).catch(error => {
                            console.log(error);
                        });
                    });
                } else {
                    alert("채팅방 연결 실패");
                    console.log(frame);
                }
            }
        });

        client.current.activate();
    }
    const subscribe = () => {
        //현재 채팅방 새로운 메시지
        client.current.subscribe(`/topic/chatting/sub/admin/newMessage/${chattingRoomInfo.chattingRoomId}`, (data) => {
            let newMessage = JSON.parse(data.body);
            newMessage.createdDate = new Date().getTime();
            setMessages(messages => [...messages, newMessage]
                .sort((a,b) => {
                    return new Date(a.createdDate).getTime() - 
                        new Date(b.createdDate).getTime()
                }));
            /** 초기 데이터 메세지 20개 미만일경우 isLast = true **/
            setIsLast(messages.length < 20);

            if (newMessage.sendMemberUUID === sessionStorage.getItem("memberUUID")) {
                let chatMessageListView = document.querySelector("#ChatMessageListView");
                chatMessageListView.scrollTop = chatMessageListView.scrollHeight;
                scrollToBottom();
                
            }
        });
        client.current.subscribe(`/topic/chatting/sub/admin/newFileMessage/${chattingRoomInfo.chattingRoomId}`, (data) => {
            let newMessage = JSON.parse(data.body);
            setMessages(messages => [...messages, newMessage]);
            setIsLast(messages.length < 20);
            if (newMessage.sendMemberUUID === sessionStorage.getItem("memberUUID")) {
                let chatMessageListView = document.querySelector("#ChatMessageListView");
                chatMessageListView.scrollTop = chatMessageListView.scrollHeight;
                scrollToBottom();
            }
        });
        client.current.subscribe(`/topic/chatting/sub/removeMessage/${chattingRoomInfo.chattingRoomId}`, (data) => {
            setMessages(messages => messages.filter(message => message.sendMessageId !== data.body));
        });
    }
    const sendMsg = () => {
        if (!client.current.connected) return;

        if (isNull(fileInput)) {
            if (isNull(messageInput)) return;

            const sendMessageDTO = {
                sendMemberUUID: sessionStorage.getItem("memberUUID"),
                sendMemberType: "MANAGER",
                content: messageInput,
                messageType: "GENERAL"
            };

            if (!isNull(replyInfo)) {
                sendMessageDTO.messageType = "REPLY";
                sendMessageDTO.targetMemberUUID = replyInfo.sendMemberUUID;
                sendMessageDTO.replyMessageId = replyInfo.sendMessageId;
                sendMessageDTO.replyMessageContent = isNull(replyInfo.content) ? null : replyInfo.content;
            }

            const chattingRoomType = chattingRoomInfo.chattingRoomType === "DUMMY" ? "public" : chattingRoomInfo.chattingRoomType.toLowerCase();

            let destination = isForceMessage ? `/topic/chatting/pub/newMessage/${chattingRoomType}/${chattingRoomInfo.chattingRoomId}/true` : `/topic/chatting/pub/newMessage/${chattingRoomType}/${chattingRoomInfo.chattingRoomId}`;
            client.current.publish({
                destination: destination,
                body: JSON.stringify(sendMessageDTO)
            });

            setMessageInput("");
            setReplyInfo(null);
            setFileInput(null);
        } else {
            if (isRequestProcessing) return;
            setIsRequestProcessing(true);

            sendFileMessage(chattingRoomInfo, sessionStorage.getItem("memberUUID"), fileInput, replyInfo, isForceMessage, () => {
                setMessageInput("");
                setReplyInfo(null);
                setFileInput(null);
                document.querySelector("#FileForSend").value = "";
                setIsRequestProcessing(false);
            }, () => {
                setIsRequestProcessing(false);
                alert("파일 전송 실패");
            });
        }
    }

    let informMessageView = isNull(informMessage) ? "" :
        <div className="InformMessageArea"
             style={{
                 height: 80,
             }}>
            <div className="icon">
                <img src="/images/notice_icon.svg"/>
            </div>
            <div className="content">
                {informMessage.messageContent}
            </div>
            <div className="spacer"/>
            <div className="icon">
                <img src="/images/trashcan.png" className="remove" onClick={() => {
                    if (isRequestProcessing || !window.confirm("공지를 제거하시겠습니까?")) return;
                    setIsRequestProcessing(true);

                    removeInform({
                        chattingRoomId: chattingRoomInfo.chattingRoomId,
                        messageId: informMessage.messageId
                    }, () => {
                        setIsRequestProcessing(false);
                    });
                }}/>
            </div>
        </div>

        const handleScroll = (e) => {
            if(e.currentTarget.scrollTop === 0) {
                
                if (isMessageLoading || isLast) {
                    return;
                }

                setIsMessageLoading(true);
                getMessages(chattingRoomInfo.chattingRoomId, messages[0].createdDate, (responseData) => {

                    console.log("[handleScroll] responseData", responseData)

                    setMessages(messages => [...responseData, ...messages]
                        .sort((a,b) => {
                            return new Date(a.createdDate).getTime() - 
                                new Date(b.createdDate).getTime()
                        }) );



                    setIsLast(responseData.length < 20);
                    setIsMessageLoading(false);
                    setFirstItemIndex(firstItemIndex + 19);

                    //계속 startReached 가 호출되는 문제 해결을 위해 20번 index(데이터로 치면 마지막에서 20번째)로 scrollTo 해줌
                    messagesRef.current.scrollToIndex({
                        index: 20,
                        align: "start",
                        behavior: "auto"
                    });
                }, () => {
                    setIsMessageLoading(false);
                    alert("메시지 목록 조회 실패");
                });

            }
        } 

    let messageListView = isNull(messages) ? "" :
    <>
        <div 
            onScroll={handleScroll}
            id="ChatMessageListView"
                  ref={messagesRef}
                  style={{
                      height: "100%",
                      overflow: "scroll",
                    //   paddingBottom : `0px !important`,
                  }}
                //   data={messages}
                //   firstItemIndex={firstItemIndex}
                //   initialTopMostItemIndex={19}
                //   startReached={() => {
                //   }}
                //   itemContent={(index, messageInfo) => {
                     
                //   }}

        >
            { 
            messages && 
            messages
            .map((messageInfo, index)=> {
                const sendMember = currentMembers.find(member => member.memberUUID === messageInfo.sendMemberUUID);
                return (
                    <ChatMessage 
                        messageInfo={messageInfo}
                        nickname={isNull(sendMember) ? "" : sendMember.memberNickname + "(" + sendMember.memberName + ")"}
                        searchKeyword={messageKeyword}
                        key={messageInfo.sendMessageId}
                        getMemberInfo={(memberUUID) => {
                            return currentMembers.find(member => member.memberUUID === memberUUID);
                        }}
                        onContextMenu={() => {
                            setContextMenuMessage(messageInfo);
                        }}
                        />

                )
            })
            }
            <div style={{ float:"left", clear: "both" }}
                 ref={messagesEndRef}>
            </div>
        </div>

    </>

    const replyTargetMember = isNull(replyInfo) ? null : currentMembers.find(member => member.memberUUID === replyInfo.sendMemberUUID);
    let replyInfoBox = isNull(replyInfo) || isNull(replyTargetMember) ? "" :
        <div id="ReplyInfoBox">
            <div id="ReplyInfo">
                <div className="target-guide">
                    <div
                        style={{color: 'white'}}>{replyTargetMember.memberNickname + "(" + replyTargetMember.memberName + ")" + '님에게 답장'}</div>
                    <div className="spacer"/>
                    <div style={{color: 'white'}} className="close" onClick={() => {
                        setReplyInfo(null);
                    }}>&times;</div>
                </div>
                <div
                    style={{color: 'white'}}
                    className="content">{`${replyInfo.messageType === "FILE" ? replyInfo.fileMetaData.fileName : replyInfo.content}`}</div>
            </div>
        </div>

    let chattingMemberList = isNull(currentMembers) ? "" : currentMembers.filter(currentMember => {
        return currentMember.memberType !== "DUMMY"
    }).sort((a, b) => {
        if (a.memberType > b.memberType) return -1
        if (b.memberType > a.memberType) return 1

        if (a.memberNickname > b.memberNickname) return -1;
        else return 1
    }).map(data =>
        <div className="ChattingMemberListItem" key={data.memberUUID}>
            {/* {

                data.memberImageUrl &&
            } */}

            <CircleImage imgSrc={data.memberImageUrl} width='20%'/>
            <span>{`${data.memberNickname}` + "(" + data.memberName + ")"}</span>
            <div className="spacer"/>
            <button onClick={() => {
                if (isRequestProcessing || !window.confirm(data.memberUUID === sessionStorage.getItem("memberUUID") ? "퇴장하시겠습니까?" : `'${data.memberNickname}' 회원을 퇴장시키시겠습니까?`)) return;
                setIsRequestProcessing(true);

                removeChattingMember({
                    chattingRoomId: chattingRoomInfo.chattingRoomId,
                    memberUUID: data.memberUUID
                }, () => {
                    if (data.memberUUID === sessionStorage.getItem("memberUUID")) window.location.href = "/chat"; //본인 퇴장
                    else window.location.href = `/chat?chattingRoomId=${props.chattingRoomInfo.chattingRoomId}`; //다른 회원 퇴장
                }, () => {
                    setIsRequestProcessing(false);
                    alert("퇴장 실패");
                });
            }}>퇴장
            </button>
        </div>
    );

    let dummyMemberList = "";
    let chattingRoomType = "";
    switch (chattingRoomInfo.chattingRoomType) {
        case "PUBLIC":
            chattingRoomType = "공개";
            break;
        case "PRIVATE":
            chattingRoomType = "비공개";
            break;
        case "DUMMY":
            chattingRoomType = "더미";

            let chattingDummyList = isNull(currentMembers) ? "" : currentMembers.filter(currentMember => {
                return currentMember.memberType === "DUMMY"
            }).sort((a, b) => {
                return a.name < b.name ? -1 : 1;
            }).map(data =>
                <div className="ChattingMemberListItem" key={data.memberUUID}>
                    {
                        data.memberImageUrl &&
                        <CircleImage imgSrc={data.memberImageUrl} width='20%'/>

                    }
                    <span>{`${data.memberNickname}`}</span>
                    <div className="spacer"/>
                    <button onClick={() => {
                        if (isRequestProcessing || !window.confirm(`'${data.memberNickname}' 회원을 퇴장시키시겠습니까?`)) return;
                        setIsRequestProcessing(true);

                        removeChattingMember({
                            chattingRoomId: chattingRoomInfo.chattingRoomId,
                            memberUUID: data.memberUUID
                        }, () => {
                            window.location.href = `/chat?chattingRoomId=${props.chattingRoomInfo.chattingRoomId}`;
                        }, () => {
                            setIsRequestProcessing(false);
                            alert("퇴장 실패");
                        });
                    }}>퇴장
                    </button>
                </div>
            );
            dummyMemberList = <div className="ChannelMemberList">
                {chattingDummyList}
            </div>
            break;
        default:
            chattingRoomType = "공개";
    }
    let chattingRoomStatus = "";
    switch (chattingRoomInfo.chattingRoomStatus) {
        case "ACTIVITY":
            chattingRoomStatus = "활동";
            break;
        case "INACTIVITY":
            chattingRoomStatus = "비활동";
            break;
        case "REMOVE":
            chattingRoomStatus = "삭제";
            break;
        default:
            chattingRoomStatus = "활동";
    }

    document.addEventListener("click", (e) => {
        let contextMenu = document.querySelector(`.context-menu`);

        if (!isNull(contextMenu)) contextMenu.style.display = "none";
    });

    const chatMessageListViewElement = document.querySelector("#ChatMessageListView");
    if (!isNull(chatMessageListViewElement)) {
        chatMessageListViewElement.addEventListener("scroll", (e) => {
            let contextMenu = document.querySelector(`.context-menu`);

            if (!isNull(contextMenu)) contextMenu.style.display = "none";
        })
    }

    /** 엔터키로 채팅 전송 **/
    const onKeyPress = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            console.log('press shift + enter = ')

        } else if (e.key === 'Enter') {
            e.preventDefault();
            console.log('press enter = ')
            sendMsg();
        }
    }

    return (
        <React.Fragment>
            <section id="ChattingView">
                <div id="InfoArea">
                    <span className="close" onClick={() => {
                        props.onClose();
                    }}>&times;</span>
                    <div className="SearchBox">
                        <input className="SearchBar" type="text" placeholder="메시지 검색"
                               style={{backgroundImage: `url('/images/search.png')`}}
                               value={messageKeyword} onChange={messageKeywordChanged}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) { //enter
                                       let searchedIndexes = [];
                                       for (let i = 0; i < messages.length; i++) {
                                           if (messages[i].messageType === "FILE") {
                                               if (messages[i].fileMetaData.fileName.includes(messageKeyword)) searchedIndexes.unshift(i);
                                           } else {
                                               if (messages[i].content.includes(messageKeyword)) searchedIndexes.unshift(i);
                                           }
                                       }

                                       if (isNull(searchedIndexes) || searchedIndex >= searchedIndexes.length - 1) {
                                           if (isMessageLoading) return;
                                           setIsMessageLoading(true);
                                           //현재 불러온 목록에 검색어 포함한 메시지가 없거나, searchedIndex 가 마지막 인덱스이면 서버에 요청
                                           getMessagesByKeyword(chattingRoomInfo.chattingRoomId, messageKeyword, messages[0].createdDate, (responseData) => {
                                               if (isNull(responseData)) {
                                                   if (isNull(searchedIndexes)) { //검색 결과가 없음
                                                       alert("검색 결과가 존재하지 않습니다");
                                                   } else { //다음 검색 결과가 없고 현재 불러온 목록에 검색 결과가 있으면 처음 검색 결과로 스크롤
                                                       setSearchedIndex(0);
                                                       messagesRef.current.scrollToIndex({
                                                           index: searchedIndexes[0],
                                                           align: "center",
                                                           behavior: "smooth"
                                                       });
                                                   }
                                               } else {  //다음 검색결과가 있으면
                                                   let newMessageList = [...responseData, ...messages].sort((a,b) => {
                                                    return new Date(a.scheduled_for).getTime() - 
                                                        new Date(b.scheduled_for).getTime()
                                                }).reverse() ;
                                                   searchedIndexes = [];
                                                   for (let i = 0; i < newMessageList.length; i++) {
                                                       if (newMessageList[i].messageType === "FILE") {
                                                           if (newMessageList[i].fileMetaData.fileName.includes(messageKeyword)) searchedIndexes.unshift(i);
                                                       } else {
                                                           if (newMessageList[i].content.includes(messageKeyword)) searchedIndexes.unshift(i);
                                                       }
                                                   }
                                                   setMessages(newMessageList);

                                                   setTimeout(() => {
                                                       messagesRef.current.scrollToIndex({
                                                           index: searchedIndexes[searchedIndex + 1],
                                                           align: "center",
                                                           behavior: "smooth"
                                                       });
                                                   }, 100);
                                               }
                                               setIsMessageLoading(false);
                                           }, () => {
                                               setIsMessageLoading(false);
                                               alert("메시지 검색 실패");
                                           });
                                       } else {
                                           //현재 불러온 메시지들 중에 검색어 포함한 메시지가 있으면 해당 메시지로 스크롤
                                           setSearchedIndex(searchedIndex + 1);
                                           messagesRef.current.scrollToIndex({
                                               index: searchedIndexes[searchedIndex + 1],
                                               align: "center",
                                               behavior: "smooth"
                                           });
                                       }
                                   }
                               }}
                        />
                    </div>
                </div>
                {informMessageView}
                <div id="ChatMessageArea" style={{height: isNull(informMessage) ? "70%" : "65%"}}>
                    {messageListView}
                    <div className="context-menu">
                        <ul>
                            <li onClick={() => {
                                setReplyInfo(contextMenuMessage);
                            }}>
                                <span>답장</span>
                            </li>
                            {isNull(contextMenuMessage) || contextMenuMessage.messageType === "FILE" ? "" :
                                <li onClick={() => {
                                    if (isRequestProcessing || !window.confirm("이 메시지를 공지 메시지로 설정하시겠습니까?")) return;
                                    setIsRequestProcessing(true);

                                    setInform({
                                        chattingRoomId: chattingRoomInfo.chattingRoomId,
                                        messageId: contextMenuMessage.sendMessageId
                                    }, () => {
                                        setIsRequestProcessing(false);
                                    });
                                }}>
                                    <span>공지 메시지로 지정</span>
                                </li>}
                            <li onClick={() => {
                                if (!window.confirm("정말 이 메시지를 삭제하시겠습니까?")) return;

                                client.current.publish({
                                    destination: `/topic/chatting/pub/removeMessage/${chattingRoomInfo.chattingRoomId}`,
                                    body: contextMenuMessage.sendMessageId
                                });
                            }}>
                                <span>삭제</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="TextFieldArea">
                    <div id="InputMessageFieldBox">
                        <div>{replyInfoBox}</div>
                        <textarea placeholder="전송할 내용을 입력하세요.(15000자 이하)"
                                  onKeyDown={onKeyPress}
                                  style={{paddingTop: isNull(replyInfo) ? "" : `${isNull(document.querySelector("#ReplyInfo")) ? 0 : document.querySelector("#ReplyInfo").clientHeight}px`}}
                                  name="inputMessage"
                                  id="InputMessageField"
                                  value={messageInput}
                                  onChange={messageInputChanged}
                                  readOnly={!isNull(fileInput)}
                        />

                    </div>
                    <div id="SendButtonBox">
                        <button id="SendButton" onClick={() => {
                            sendMsg();
                        }}>
                            전송
                        </button>
                    </div>
                    <div>
                        <label>파일 첨부 <input type="file" id="FileForSend" onChange={fileInputChanged}
                                            accept="image/*,.zip,.pdf"/></label>
                        <label>Push 전송 <input type="checkbox" checked={isForceMessage}
                                              onChange={isForceMessageChanged}/></label>
                    </div>
                </div>
            </section>
            <section id="ChannelOptionArea">
                <div className="ChannelMemberListArea" style={{  display : 'block !important'}}>
                    <div className="AddChatMemberButtonArea">
                        <span>참여자 리스트</span>
                        <button onClick={event => {
                            getMembers({
                                chattingRoomId: chattingRoomInfo.chattingRoomId,
                                chattingRoomType: chattingRoomInfo.chattingRoomType,
                                memberUUID: sessionStorage.getItem("memberUUID")
                            }, (responseData) => {
                                setAvailableMembers(responseData);
                                document.querySelector("#SelectMemberModal").style.display = "block";
                            }, () => {
                                alert("회원 목록 조회 실패");
                            });
                        }}>추가
                        </button>
                    </div>
                    <div className="ChannelInfoArea">
                        <div className="InfoArea">
                            {/* chattingRoomImageUrl */}
                            {/* {`${chattingRoomInfo.chattingRoomImageUrl}`} */}
                            {
                                !chattingRoomInfo.chattingRoomImageUrl &&
                                <CircleImage imgSrc={``} width='20%'/>
                            }
                            {
                                chattingRoomInfo.chattingRoomImageUrl &&

                                <CircleImage imgSrc={chattingRoomInfo.chattingRoomImageUrl} width='20%'/>
                            }
                            <div className="InfoTextBox">
                                <h4>{chattingRoomInfo.chattingRoomTitle}</h4>
                                <h4>{chattingRoomType} / {chattingRoomStatus} / {currentMembers.length}명</h4>
                            </div>
                        </div>
                    </div>
                    <div className="AddChatMemberButtonArea">

                    <div className="ButtonArea" style={{ flexDirection : 'row', width : '100%', flexWrap: 'nowrap', }}>
                        <button className="modify" onClick={event => {
                            document.querySelector("#ModifyChannelInfoModal").style.display = "block";
                        }}>수정
                        </button>
                        &nbsp;
                        <button className="inactive" onClick={event => {
                            if (isRequestProcessing || !window.confirm(`${chattingRoomInfo.chattingRoomTitle} 채팅방을 비활성화 하시겠습니까?`)) return;
                            setIsRequestProcessing(true);

                            modifyChattingRoomStatus({
                                chattingRoomId: chattingRoomInfo.chattingRoomId,
                                chattingRoomStatus: "INACTIVITY"
                            }, () => {
                                setIsRequestProcessing(false);
                            });
                        }}>비활성화
                        </button>
                        &nbsp;
                        <button className="remove" onClick={event => {
                            if (isRequestProcessing || !window.confirm(`${chattingRoomInfo.chattingRoomTitle} 채팅방을 정말 삭제하시겠습니까?`)) return;
                            setIsRequestProcessing(true);

                            removeChattingRoom(chattingRoomInfo.chattingRoomId, () => {
                                setIsRequestProcessing(false);
                            });
                        }}>삭제
                        </button>
                    </div>
                    </div>

                    <div className="ChannelMemberList" style={{ overflow : "scroll"}}>
                        {chattingMemberList}
                    </div>
                    {dummyMemberList}


                </div>
            </section>

            <ModifyChatRoomModal chattingRoomInfo={chattingRoomInfo}/>

            <SelectMemberModal chattingRoomInfo={chattingRoomInfo} availableMembers={availableMembers}
                               currentMembers={currentMembers} onAdd={(returnedMembers) => {
                if (isRequestProcessing) return;
                setIsRequestProcessing(true);

                const members = returnedMembers.map((data) => {
                    return {
                        memberUUID: data.uuid,
                        memberImageUrl: data.imageUrl,
                        memberNickname: data.nickname,
                        memberType: data.memberType,
                        memberLastAccessDate: null
                    }
                });

                addChattingMembers({
                    memberIds: members.map(data => data.memberUUID),
                    chattingRoomId: chattingRoomInfo.chattingRoomId
                }, () => {
                    window.location.href = `/chat?chattingRoomId=${props.chattingRoomInfo.chattingRoomId}`;
                }, () => {
                    setIsRequestProcessing(false);
                    alert("회원 추가 실패");
                });
            }}/>
        </React.Fragment>
    );
}

function modifyChattingRoom(chattingRoomId, type, title, image, onSuccess, onFail) {
    let data = new FormData();
    data.append("chattingRoomId", chattingRoomId);
    data.append("chattingRoomType", type);
    data.append("chattingRoomTitle", title);
    data.append("chattingRoomImage", image);

    axios.put(
        "/admin/chatting/chattingRoom",
        data,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken"),
                'Content-type' : `multipart/formdata`
            }
        }
    ).then(() => {
        onSuccess();
    }).catch(error => {
        console.log(error);
        onFail();
    });
}

function ModifyChatRoomModal(props) {

    let [chattingRoomTypeInput, setChattingRoomTypeInput] = useState(props.chattingRoomInfo.chattingRoomType);
    let [chattingRoomTitleInput, setChattingRoomTitleInput] = useState(props.chattingRoomInfo.chattingRoomTitle);
    let [imageInput, setImageInput] = useState("");
    let [imageForDisplay, setImageForDisplay] = useState(props.chattingRoomInfo.chattingRoomImageUrl);

    const chattingRoomTypeInputChanged = e => setChattingRoomTypeInput(e.target.value);
    const chattingRoomNameInputChanged = e => setChattingRoomTitleInput(e.target.value);
    const imageInputChanged = ev => {
        if (ev.target.files && ev.target.files[0]) {
            setImageInput(ev.target.files[0]);

            let reader = new FileReader();
            reader.onload = e => {
                setImageForDisplay(e.target.result);
            }
            reader.readAsDataURL(ev.target.files[0]);
        }
    }

    window.addEventListener("click", (e) => {
        let modal = document.querySelector("#ModifyChannelInfoModal");
        if (e.target === modal) {
            setChattingRoomTypeInput(props.chattingRoomInfo.chattingRoomType);
            setChattingRoomTitleInput(props.chattingRoomInfo.chattingRoomTitle);
            modal.style.display = "none";
        }
    });

    return (
        <div id="ModifyChannelInfoModal" className="modal">

            {/* Modal content */}
            <div className="modal-content">
                <span className="close" onClick={event => {
                    setChattingRoomTypeInput(props.chattingRoomInfo.chattingRoomType);
                    setChattingRoomTitleInput(props.chattingRoomInfo.chattingRoomTitle);
                    document.querySelector("#ModifyChannelInfoModal").style.display = "none";
                }}>&times;</span>
                <h1>채널 정보 수정</h1>
                <div className="Form">
                    <div className="InputList">
                        <div className="InputLine">
                            <span>채널 종류</span>
                            <select value={chattingRoomTypeInput} onChange={chattingRoomTypeInputChanged}>
                                <option value="PUBLIC">공개</option>
                                <option value="PRIVATE">비공개</option>
                                <option value="DUMMY">더미</option>
                            </select>
                        </div>
                        <div className="InputLine">
                            <span>채널 이름</span>
                            <input type="text" placeholder="이름 입력" value={chattingRoomTitleInput}
                                   onChange={chattingRoomNameInputChanged}/>
                        </div>
                        <div className="InputLine">
                            <span>채널 이미지</span>
                            <input type="file" accept="image/png, image/gif, image/jpeg" placeholder="채널 이미지"
                                   onChange={imageInputChanged}/>
                        </div>
                        <div className="InputLine ImageLine">
                            <img src={imageForDisplay} alt=""/>
                        </div>
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={event => {
                            if (isNull(chattingRoomTitleInput)) {
                                alert("채널 이름을 입력하세요");
                            }

                            if (imageForDisplay.indexOf("https://") > -1) {

                            } else {

                                postChattingRoomImage(props.chattingRoomInfo.chattingRoomId,imageInput, () => {
                                    // alert("수정되었습니다.");
                                    window.location.href = `/chat?chattingRoomId=${props.chattingRoomInfo.chattingRoomId}`;
                                }, () => {
                                    alert("이미지 업로드 실패");
                                });
                            }

                            modifyChattingRoom(props.chattingRoomInfo.chattingRoomId, chattingRoomTypeInput, chattingRoomTitleInput, imageInput, () => {
                                // alert("수정되었습니다.");
                                window.location.href = `/chat?chattingRoomId=${props.chattingRoomInfo.chattingRoomId}`;
                            }, () => {
                                alert("수정 실패");
                            });


                        }}>수정
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

function SelectMemberModal(props) {

    let [availableMembers, setAvailableMembers] = useState([]);
    let [keyword, setKeyword] = useState("");

    const keywordChanged = e => setKeyword(e.target.value);

    useEffect(() => {
        setAvailableMembers(props.availableMembers.filter(availableMember => {
            return !props.currentMembers.some(currentMember => currentMember.id === availableMember.id);
        }));
    }, [props.availableMembers, props.currentMembers]);

    window.addEventListener("click", (e) => {
        let modal = document.querySelector("#SelectMemberModal")
        if (e.target === modal) {
            modal.style.display = "none";
            setKeyword("");
        }
    });

    let memberList = isNull(availableMembers) ? "" :
        availableMembers.map(data =>
            <div key={data.id}>
                <input type="checkbox" name={data.name} value={JSON.stringify(data)}/>
                <span>{`${data.nickname}(${data.name})`}</span>
            </div>
        );

    return (
        <div id="SelectMemberModal" className="modal">

            <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#SelectMemberModal").style.display = "none";
                        setKeyword("");
                    }}>&times;</span>
                <h1>채널에 참여할 회원</h1>
                <div className="Form">
                    <div className="SearchArea">
                        <input type="text" value={keyword} onChange={keywordChanged}/>
                        <button onClick={event => {
                            getMembers({
                                chattingRoomId: props.chattingRoomInfo.chattingRoomId,
                                keyword: keyword,
                                chattingRoomType: props.chattingRoomInfo.chattingRoomType,
                                memberUUID: sessionStorage.getItem("memberUUID")
                            }, (responseData) => {
                                setAvailableMembers(responseData.filter(availableMember => {
                                    return !props.currentMembers.some(currentMember => currentMember.id === availableMember.id);
                                }));
                            }, () => {
                                alert("검색 실패");
                            });
                        }}>검색
                        </button>
                    </div>
                    <div className="MemberList">
                        {memberList}
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={() => {
                            let result = [];
                            document.querySelectorAll("#SelectMemberModal input[type=checkbox]").forEach(checkbox => {
                                if (checkbox.checked) {
                                    result.push(JSON.parse(checkbox.value));
                                }
                            });

                            if (isNull(result)) {
                                alert("채널에 참여할 회원을 선택해주세요!");
                            } else {
                                if (!window.confirm("선택하신 회원들을 이 채널에 추가하시겠습니까?")) return;

                                props.onAdd(result);
                                document.querySelector("#SelectMemberModal").style.display = "none";
                                setKeyword("");
                            }
                        }}>추가
                        </button>
                    </div>
                </div>
            </div>
            <style>{`
                #ChatMessageListView > div > div > div {
                    height: 100%;
                    padding-bottom: 0px !important;
                }
            `}</style>
        </div>
        
    );
}

export default ChattingView;
