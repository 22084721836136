import React, {useEffect, useRef, useState} from "react";
import "../Chat.scss";
import CircleImage from "../../common/CircleImage";
import * as StompJS from "@stomp/stompjs";
import {isNull, refreshToken} from "../../../Common";
import axios from "axios";
import PrevChatMessage from "./PrevChatMessage";
import {Virtuoso} from "react-virtuoso";
import ChatMessage from "../ChatMessage";

function getChattingRoomInitData(chattingRoomId, onLoad, onFail) {
    axios.get(
        "/admin/chatting/chattingRoom/prev/initData",
        {
            params: {
                chattingRoomId: chattingRoomId,
                memberUUID: sessionStorage.getItem("memberUUID")
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getChattingRoomInitData(chattingRoomId, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function getMessages(chattingRoomId, lastMessageCreatedDate, onLoad, onFail) {
    axios.get(
        "/admin/chatting/chattingRoom/prev/messages",
        {
            params: {
                chattingRoomId: chattingRoomId,
                lastMessageCreatedDate: lastMessageCreatedDate
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMessages(chattingRoomId, lastMessageCreatedDate, onLoad, onFail)
            });
        } else {
            console.log(error);
            onFail();
        }
    })
}

function getMessagesByKeyword(chattingRoomId, keyword, lastMessageCreatedDate, onLoad, onFail) {
    axios.get(
        "/admin/chatting/chattingRoom/prev/message/search",
        {
            params: {
                keyword: keyword,
                chattingRoomId: chattingRoomId,
                lastMessageCreatedDate: lastMessageCreatedDate
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMessagesByKeyword(chattingRoomId, keyword, lastMessageCreatedDate, onLoad, onFail)
            });
        } else {
            console.log(error);
            onFail();
        }
    })
}

function modifyChattingRoomStatus(modifyInfo, onFail) {
    axios.put(
        "/admin/chatting/chattingRoom/status",
        modifyInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("채팅방 상태가 변경되었습니다. 목록을 확인하세요.");
        window.location.href = "/prev/chat";
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyChattingRoomStatus(modifyInfo, onFail);
            });
        } else {
            onFail();
            console.log(error);
            alert("채팅방 상태 변경 실패");
        }
    });
}

function removeChattingRoom(chattingRoomId, onFail) {
    axios.delete(
        "/admin/chatting/chattingRoom",
        {
            params: {
                chattingRoomId: chattingRoomId
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("채팅방이 삭제되었습니다. 목록을 확인하세요.");
        window.location.href = "/prev/chat";
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeChattingRoom(chattingRoomId, onFail);
            });
        } else {
            onFail();
            console.log(error);
            alert("채팅방 삭제 실패");
        }
    });
}

function PrevChattingView(props) {
    const chattingRoomInfo = props.chattingRoomInfo;
    const client = useRef({});
    const messagesRef = useRef(null);

    let [messages, setMessages] = useState([]);
    let [firstItemIndex, setFirstItemIndex] = useState(0);
    let [messageKeyword, setMessageKeyword] = useState("");
    let [searchedIndex, setSearchedIndex] = useState(-1);
    let [informMessage, setInformMessage] = useState();
    let [isMessageLoading, setIsMessageLoading] = useState(false);
    let [isLast, setIsLast] = useState(false);
    let [isRequestProcessing, setIsRequestProcessing] = useState(false);
    let [currentMembers, setCurrentMembers] = useState([]);

    useEffect(() => {
        if (isMessageLoading) return;

        setIsMessageLoading(true);
        getChattingRoomInitData(chattingRoomInfo.chattingRoomId, (responseData) => {
            setCurrentMembers(responseData.chattingRoomMembers);
            setMessages(responseData.chattingRoomMessages.reverse());
            setInformMessage(responseData.chattingRoomInform);
            setIsMessageLoading(false);
        }, () => {
            setIsMessageLoading(false);
            alert("채널 정보 조회 실패");
        });
        connect();

        return () => {
            setCurrentMembers([]);
            setMessages([]);
            setInformMessage(null);
            setIsMessageLoading(false);
            setIsLast(false);
            setMessageKeyword("");
            setSearchedIndex(-1);
            client.current.deactivate();
        }
    }, [props.chattingRoomInfo]);

    const messageKeywordChanged = e => {
        setMessageKeyword(e.target.value);
        setSearchedIndex(-1);
    }
    const connect = () => {
        const brokerURL = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:8080/websocket/invest";

        client.current = new StompJS.Client({
            brokerURL: brokerURL,
            connectHeaders: {
                Authorization: sessionStorage.getItem("accessToken")
            },
            debug: (str) => {
                console.log(str);
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => {
                subscribe();
            },
            onStompError: (frame) => {
                if (frame.headers.message.includes("invalid token")) {
                    refreshToken(() => {
                        client.current.deactivate()
                            .then(() => {
                                connect();
                            }).catch(error => {
                            console.log(error);
                        });
                    });
                } else {
                    alert("채팅방 연결 실패");
                    console.log(frame);
                }
            }
        });

        client.current.activate();
    }
    const subscribe = () => {
        //현재 채팅방 새로운 메시지
        client.current.subscribe(`/topic/chatting/sub/admin/newMessage/${chattingRoomInfo.chattingRoomId}`, (data) => {
            let newMessage = JSON.parse(data.body);
            newMessage.createdDate = new Date().getTime();
            setMessages(messages => [...messages, newMessage]);

            if (newMessage.sendMemberUUID === sessionStorage.getItem("memberUUID")) {
                let chatMessageListView = document.querySelector("#ChatMessageListView");
                chatMessageListView.scrollTop = chatMessageListView.scrollHeight;
            }
        });
        client.current.subscribe(`/topic/chatting/sub/admin/newFileMessage/${chattingRoomInfo.chattingRoomId}`, (data) => {
            let newMessage = JSON.parse(data.body);
            setMessages(messages => [...messages, newMessage]);

            if (newMessage.sendMemberUUID === sessionStorage.getItem("memberUUID")) {
                let chatMessageListView = document.querySelector("#ChatMessageListView");
                chatMessageListView.scrollTop = chatMessageListView.scrollHeight;
            }
        });
    }

    let informMessageView = isNull(informMessage) ? "" :
        <div className="InformMessageArea"
             style={{
                 height: 80,
             }}>
            <div className="icon">
                <img src="/images/notice_icon.svg"/>
            </div>
            <div className="content">
                {informMessage.messageContent}
            </div>
        </div>

    let messageListView = isNull(messages) ? "" :
        <Virtuoso id="ChatMessageListView"
                  ref={messagesRef}
                  style={{
                      height: '100%',
                  }}
                  data={messages}
                  firstItemIndex={firstItemIndex}
                  initialTopMostItemIndex={19}
                  startReached={() => {
                      if (isMessageLoading || isLast) return;

                      setIsMessageLoading(true);
                      getMessages(chattingRoomInfo.chattingRoomId, messages[0].createdDate, (responseData) => {
                          setMessages(messages => [...responseData.reverse(), ...messages]);
                          setIsLast(responseData.length < 20);
                          setIsMessageLoading(false);
                          setFirstItemIndex(firstItemIndex + 19);

                          //계속 startReached가 호출되는 문제 해결을 위해 20번 index(데이터로 치면 마지막에서 20번째)로 scrollTo 해줌
                          messagesRef.current.scrollToIndex({
                              index: 20,
                              align: "start",
                              behavior: "auto"
                          });
                      }, () => {
                          setIsMessageLoading(false);
                          alert("메시지 목록 조회 실패");
                      });
                  }}
                  itemContent={(index, messageInfo) => {
                      const sendMember = currentMembers.find(member => member.memberUUID === messageInfo.sendMemberUUID);

                      return <PrevChatMessage messageInfo={messageInfo}
                                              nickname={isNull(sendMember) ? "" : sendMember.memberNickname}
                                              searchKeyword={messageKeyword}
                                              key={messageInfo.sendMessageId}
                                              getMemberInfo={(memberUUID) => {
                                                  return currentMembers.find(member => member.memberUUID === memberUUID);
                                              }}
                      />
                  }}
        />

    let chattingMemberList = isNull(currentMembers) ? "" : currentMembers.filter(currentMember => {
        return currentMember.memberType !== "DUMMY"
    }).sort((a, b) => {
        if (a.memberType > b.memberType) return -1
        if (b.memberType > a.memberType) return 1

        if (a.memberNickname > b.memberNickname) return -1;
        else return 1
    }).map(data =>
        <div className="ChattingMemberListItem" key={data.memberUUID}>
            <CircleImage imgSrc={data.memberImageUrl} width='20%'/>
            <span>{`${data.memberNickname}` + "(" + data.memberName + ")"}</span>
            <div className="spacer"/>
        </div>
    );

    let dummyMemberList = "";
    let chattingRoomType = "";
    switch (chattingRoomInfo.chattingRoomType) {
        case "PUBLIC":
            chattingRoomType = "공개";
            break;
        case "PRIVATE":
            chattingRoomType = "비공개";
            break;
        case "DUMMY":
            chattingRoomType = "더미";

            let chattingDummyList = isNull(currentMembers) ? "" : currentMembers.filter(currentMember => {
                return currentMember.memberType === "DUMMY"
            }).sort((a, b) => {
                return a.name < b.name ? -1 : 1;
            }).map(data =>
                <div className="ChattingMemberListItem" key={data.memberUUID}>
                    <CircleImage imgSrc={data.memberImageUrl} width='20%'/>
                    <span>{`${data.memberNickname}`}</span>
                    <div className="spacer"/>
                </div>
            );
            dummyMemberList = <div className="ChannelMemberList">
                {chattingDummyList}
            </div>
            break;
        default:
            chattingRoomType = "공개";
    }
    let chattingRoomStatus = "";
    let chattingRoomInfoButtons = "";
    switch (chattingRoomInfo.chattingRoomStatus) {
        case "ACTIVITY":
            chattingRoomStatus = "활동";
            break;
        case "INACTIVITY":
            chattingRoomStatus = "비활동";
            chattingRoomInfoButtons = <React.Fragment>
                <button className="inactive" onClick={event => {
                    if (isRequestProcessing || !window.confirm(`${chattingRoomInfo.chattingRoomTitle} 채팅방을 활성화 하시겠습니까?`)) return;
                    setIsRequestProcessing(true);

                    modifyChattingRoomStatus({
                        chattingRoomId: chattingRoomInfo.chattingRoomId,
                        chattingRoomStatus: "ACTIVITY"
                    }, () => {
                        setIsRequestProcessing(false);
                    });
                }}>활성화
                </button>
                <button className="remove" onClick={event => {
                    if (isRequestProcessing || !window.confirm(`${chattingRoomInfo.chattingRoomTitle} 채팅방을 정말 삭제하시겠습니까?`)) return;
                    setIsRequestProcessing(true);

                    removeChattingRoom(chattingRoomInfo.chattingRoomId, () => {
                        setIsRequestProcessing(false);
                    });
                }}>삭제
                </button>
            </React.Fragment>
            break;
        case "REMOVE":
            chattingRoomStatus = "삭제";
            break;
        default:
            chattingRoomStatus = "활동";
    }

    return (
        <React.Fragment>
            <section id="ChattingView">
                <div id="InfoArea">
                    <span className="close" onClick={() => {
                        props.onClose();
                    }}>&times;</span>
                    <div className="SearchBox">
                        <input className="SearchBar" type="text" placeholder="메시지 검색"
                               style={{backgroundImage: `url('/images/search.png')`}}
                               value={messageKeyword} onChange={messageKeywordChanged}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) { //enter
                                       let searchedIndexes = [];
                                       for (let i = 0; i < messages.length; i++) {
                                           if (messages[i].messageType === "FILE") {
                                               if (messages[i].fileMetaData.fileName.includes(messageKeyword)) searchedIndexes.unshift(i);
                                           } else {
                                               if (messages[i].content.includes(messageKeyword)) searchedIndexes.unshift(i);
                                           }
                                       }

                                       if (isNull(searchedIndexes) || searchedIndex >= searchedIndexes.length - 1) {
                                           if (isMessageLoading) return;
                                           setIsMessageLoading(true);
                                           //현재 불러온 목록에 검색어 포함한 메시지가 없거나, searchedIndex 가 마지막 인덱스이면 서버에 요청
                                           getMessagesByKeyword(chattingRoomInfo.chattingRoomId, messageKeyword, messages[0].createdDate, (responseData) => {
                                               if (isNull(responseData)) {
                                                   if (isNull(searchedIndexes)) { //검색 결과가 없음
                                                       alert("검색 결과가 존재하지 않습니다");
                                                   } else { //다음 검색 결과가 없고 현재 불러온 목록에 검색 결과가 있으면 처음 검색 결과로 스크롤
                                                       setSearchedIndex(0);
                                                       messagesRef.current.scrollToIndex({
                                                           index: searchedIndexes[0],
                                                           align: "center",
                                                           behavior: "smooth"
                                                       });
                                                   }
                                               } else {  //다음 검색결과가 있으면
                                                   let newMessageList = [...responseData.reverse(), ...messages];
                                                   searchedIndexes = [];
                                                   for (let i = 0; i < newMessageList.length; i++) {
                                                       if (newMessageList[i].messageType === "FILE") {
                                                           if (newMessageList[i].fileMetaData.fileName.includes(messageKeyword)) searchedIndexes.unshift(i);
                                                       } else {
                                                           if (newMessageList[i].content.includes(messageKeyword)) searchedIndexes.unshift(i);
                                                       }
                                                   }
                                                   setMessages(newMessageList);

                                                   setTimeout(() => {
                                                       messagesRef.current.scrollToIndex({
                                                           index: searchedIndexes[searchedIndex + 1],
                                                           align: "center",
                                                           behavior: "smooth"
                                                       });
                                                   }, 100);
                                               }
                                               setIsMessageLoading(false);
                                           }, () => {
                                               setIsMessageLoading(false);
                                               alert("메시지 검색 실패");
                                           });
                                       } else {
                                           //현재 불러온 메시지들 중에 검색어 포함한 메시지가 있으면 해당 메시지로 스크롤
                                           setSearchedIndex(searchedIndex + 1);
                                           messagesRef.current.scrollToIndex({
                                               index: searchedIndexes[searchedIndex + 1],
                                               align: "center",
                                               behavior: "smooth"
                                           });
                                       }
                                   }
                               }}
                        />
                    </div>
                </div>
                {informMessageView}
                <div id="ChatMessageArea" style={{height: isNull(informMessage) ? "95%" : "90%"}}>
                    {messageListView}
                </div>
            </section>
            <section id="ChannelOptionArea">
                <div className="ChannelInfoArea">
                    <div className="InfoArea">
                        <CircleImage imgSrc={chattingRoomInfo.chattingRoomImageUrl} width='20%'/>
                        <div className="InfoTextBox">
                            <h4>{chattingRoomInfo.chattingRoomTitle}</h4>
                            <h4>{chattingRoomType} / {chattingRoomStatus} / {currentMembers.length}명</h4>
                        </div>
                    </div>
                    <div className="ButtonArea">
                        {chattingRoomInfoButtons}
                    </div>
                </div>
                <div className="ChannelMemberListArea">
                    <div className="AddChatMemberButtonArea">
                        <span>참여자 리스트</span>
                    </div>
                    <div className="ChannelMemberList">
                        {chattingMemberList}
                    </div>
                    {dummyMemberList}
                </div>
            </section>
        </React.Fragment>
    );
}

export default PrevChattingView;
