import React, { useEffect, useState } from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import TopBar from "../menu/TopBar";
import "./MacroList.scss";
import AddMacroModal from "./AddMacroModal";
import ModifyMacroModal from "./ModifyMacroModal";
import QueryString from "qs";
import {isNull} from "../../Common";

async function getMacros(searchKeyword, pageNumber) {
    let params = {
        page: pageNumber
    }
    if (searchKeyword !== undefined && searchKeyword !== null && searchKeyword.length !== 0) {
        params.keyword = searchKeyword
    }

    const response = await axios.get(
        "/admin/macro/list",
        {
            params: params,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    );

    return response.data;
}

function MacroList(props) {

    const params = QueryString.parse(props.location.search.replace('?', ''));

    let pageNum = parseInt(props.match.params.pageNumber) - 1;
    let [macroData, setMacroData] = useState("");
    let [searchKeyword, setSearchKeyword] = useState(params.searchKeyword);
    let [macroForModify, setMacroForModify] = useState("");

    const searchKeywordChanged = e => setSearchKeyword(e.target.value);

    useEffect(() => {
        window.scrollTo(0, 0);
        getMacros(searchKeyword, pageNum)
            .then(responseData => {
                setMacroData(responseData);
            }).catch(error => {
                alert("조회 실패");
                console.log(error);
            });
    }, [props.match.params.pageNumber]);

    let trList = isNull(macroData) || isNull(macroData.content) ? <tr><td colSpan={4}>검색 결과 없음</td></tr> : macroData.content.map((data) =>
        <tr key={data.id} onClick={event => {
            setMacroForModify(data);
            document.querySelector("#ModifyMacroModal").style.display = "block";
        }}>
            <td>{data.text}</td>
        </tr>
    );

    let minPage = pageNum - (pageNum % 10) + 1
    let maxPage = pageNum - (pageNum % 10) + 10

    if (maxPage > macroData.totalPages) {
        maxPage = macroData.totalPages
    }

    let pageList = [];
    let query = {};
    if (searchKeyword !== undefined && searchKeyword !== null && searchKeyword.length !== 0) {
        query.searchKeyword = searchKeyword
    }
    const queryString = Object.entries(query).map(e => e.join("=")).join("&");

    for (let i = minPage; i <= maxPage; i++) {
        const url = `/macro/list/${i}?`;

        pageList.push(
            <Link to={`${url}${queryString}`} key={i}>
                {i}
            </Link>
        );
    }
    if (minPage > 1) {
        const url = `/macro/list/${minPage - 1}`;

        pageList.unshift(
            <Link to={`${url}${queryString}`} key={'prev'}>
                이전
            </Link>
        )
    }
    if (maxPage < macroData.totalPages) {
        const url = `/macro/list/${maxPage + 1}`;

        pageList.push(
            <Link to={`${url}${queryString}`} key={'next'}>
                다음
            </Link>
        )
    }

    window.onclick = ev => {
        let addModal = document.querySelector("#AddMacroModal");
        let modifyModal = document.querySelector("#ModifyMacroModal");
        if (ev.target === addModal) {
            addModal.style.display = "none";
        }
        if (ev.target === modifyModal) {
            modifyModal.style.display = "none";
        }
    }

    return (
        <>
            <TopBar/>
            <div className="MacroListContainer">
                <div className="TableContainer">
                    <div className="SearchBarWrapper">
                        <input type="text" placeholder={"검색"} value={searchKeyword} onChange={searchKeywordChanged} onKeyUp={event => {
                            if (event.keyCode === 13) { //enter
                                window.location.href = `/macro/list/${props.match.params.pageNumber}?searchKeyword=${searchKeyword}`;
                            }
                        }}/>
                        <button onClick={event => {
                            window.location.href = `/macro/list/${props.match.params.pageNumber}?searchKeyword=${searchKeyword}`;
                        }}>검색</button>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>매크로 내용</th>
                        </tr>
                        </thead>
                        <tbody>
                        {trList}
                        </tbody>
                    </table>
                    <div className="ButtonList">
                        <button onClick={event => {
                            document.querySelector("#AddMacroModal").style.display = "block";
                        }}>매크로 등록</button>
                        <div className="PageList">
                            {pageList}
                        </div>
                    </div>
                </div>
            </div>

            <AddMacroModal />
            <ModifyMacroModal macro={macroForModify} />
        </>
    );
}

export default MacroList;