import React, {useEffect, useState} from "react";
import "./Chat.scss";
import axios from "axios";
import {isNull, refreshToken} from "../../Common";

function getMembers(requestInfo, onLoad, onFail) {
    axios.get(
        "/admin/member/list/chat/available",
        {
            params: requestInfo,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMembers(requestInfo, onLoad, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    })
}

function createChattingRoom(chattingRoomInfo, image, memberIds, onSuccess, onFail) {
    axios.post(
        "/admin/chatting/createChattingRoom",
        chattingRoomInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        const chattingRoomId = response.data;

        addChattingMembers(chattingRoomId, memberIds, () => {
            if (!isNull(image)) postChattingRoomImage(chattingRoomId, image, onSuccess, onFail);
            else onSuccess();
        }, onFail);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                createChattingRoom(chattingRoomInfo, image);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function postChattingRoomImage(chattingRoomId, image, onSuccess, onFail) {
    let data = new FormData();
    data.append("chattingRoomId", chattingRoomId);
    data.append("file", image);

    axios.post(
        "/admin/chatting/createChattingRoom/image",
        data,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                postChattingRoomImage(chattingRoomId, image, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function addChattingMembers(chattingRoomId, memberIds, onSuccess, onFail) {
    axios.post(
        "/admin/chatting/invite",
        {
            chattingRoomId: chattingRoomId,
            memberIds: memberIds
        },
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onSuccess();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                addChattingMembers(chattingRoomId, memberIds, onSuccess, onFail);
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function AddChatRoomView(props) {

    let [isLoading, setIsLoading] = useState(false);
    let [chattingRoomTypeInput, setChattingRoomTypeInput] = useState("PUBLIC");
    let [chattingRoomNameInput, setChattingRoomNameInput] = useState("");
    let [imageInput, setImageInput] = useState("");
    let [imageForDisplay, setImageForDisplay] = useState();
    let [availableManagers, setAvailableManagers] = useState([]);
    let [selectedManagers, setSelectedManagers] = useState([]);
    let [availableMembers, setAvailableMembers] = useState([]);
    let [selectedMembers, setSelectedMembers] = useState([]);
    let [availableDummies, setAvailableDummies] = useState([]);
    let [selectedDummies, setSelectedDummies] = useState([]);

    const chattingRoomTypeInputChanged = e => setChattingRoomTypeInput(e.target.value);
    const chattingRoomNameInputChanged = e => setChattingRoomNameInput(e.target.value);
    const imageInputChanged = ev => {
        if (ev.target.files && ev.target.files[0]) {
            setImageInput(ev.target.files[0]);

            let reader = new FileReader();
            reader.onload = e => {
                setImageForDisplay(e.target.result);
            }
            reader.readAsDataURL(ev.target.files[0]);
        }
    }

    let selectedManagerList = isNull(selectedManagers) ? "채널에 참여할 관리자를 추가해주세요!" :
        selectedManagers.map(data =>
            <div className="SelectedManagerListItem">
                <span>{`${data.nickname}(${data.name})`}</span>
                <button className="remove" onClick={event => {
                    setSelectedManagers(selectedManagers.filter(manager => manager.id !== data.id));
                }}>취소</button>
            </div>
        );

    let selectedMemberList = isNull(selectedMembers) ? "채널에 참여할 회원을 추가해주세요!" :
        selectedMembers.map(data =>
            <div className="SelectedMemberListItem">
                <span>{`${data.nickname}(${data.name})`}</span>
                <button className="remove" onClick={event => {
                    setSelectedMembers(selectedMembers.filter(member => member.id !== data.id));
                }}>취소</button>
            </div>
        );

    let selectedDummyList = isNull(selectedDummies) ? "채널에 참여할 더미 회원을 추가해주세요!" :
        selectedDummies.map(data =>
            <div className="SelectedDummyListItem">
                <span>{`${data.nickname}(${data.name})`}</span>
                <button className="remove" onClick={event => {
                    setSelectedDummies(selectedDummies.filter(dummy => dummy.id !== data.id));
                }}>취소</button>
            </div>
        );

    let dummyListView = chattingRoomTypeInput !== "DUMMY" ? "" :
        <React.Fragment>
            <div>
                <span>더미</span>
                <button className="add" onClick={event => {
                    getMembers({
                        chattingRoomType: chattingRoomTypeInput,
                        memberType: "DUMMY",
                        memberUUID: sessionStorage.getItem("memberUUID")
                    }, (responseData) => {
                        setAvailableDummies(responseData);
                        document.querySelector("#SelectDummyModal").style.display = "block";
                    }, () => {
                        alert("더미 회원 목록 조회 실패");
                    });
                }}>추가</button>
            </div>
            <div className="SelectedDummyList">
                {selectedDummyList}
            </div>
        </React.Fragment>;

    return (
        <React.Fragment>
            <section id="AddChatRoomView">
                <div className="TitleArea">
                    <span className="close" onClick={event => {
                        props.onClose();
                    }}>&times;</span>
                </div>
                <h3>채널 생성하기</h3>
                <div className="InputArea">
                    <div className="InputLine">
                        <span>채널 종류</span>
                        <select value={chattingRoomTypeInput} onChange={chattingRoomTypeInputChanged}>
                            <option value="PUBLIC">공개</option>
                            <option value="PRIVATE">비공개</option>
                            <option value="DUMMY">더미</option>
                        </select>
                    </div>
                    <div className="InputLine">
                        <span>채널 이름</span>
                        <input type="text" placeholder="이름 입력" value={chattingRoomNameInput} onChange={chattingRoomNameInputChanged}/>
                    </div>
                    <div className="InputLine">
                        <span>채널 이미지</span>
                        <input type="file" accept="image/png, image/gif, image/jpeg" placeholder="채널 이미지" onChange={imageInputChanged}/>
                    </div>
                    <div className="InputLine ImageLine">
                        <img src={imageForDisplay} alt=""/>
                    </div>
                </div>
                <h3>참여자 구성</h3>
                <div className="SelectMemberArea">
                    <div>
                        <span>관리자</span>
                        <button className="add" onClick={(event => {
                            getMembers({
                                chattingRoomType: chattingRoomTypeInput,
                                memberType: "MANAGER",
                                memberUUID: sessionStorage.getItem("memberUUID")
                            }, (responseData) => {
                                setAvailableManagers(responseData.filter(member => member.uuid !== sessionStorage.getItem("memberUUID")));
                                document.querySelector("#SelectManagerModal").style.display = "block";
                            }, () => {
                                alert("관리자 목록 조회 실패");
                            });
                        })}>추가</button>
                    </div>
                    <div className="SelectedManagerList">
                        {selectedManagerList}
                    </div>
                    <div>
                        <span>회원</span>
                        <button className="add" onClick={event => {
                            getMembers({
                                chattingRoomType: chattingRoomTypeInput,
                                memberType: "GENERAL",
                                memberUUID: sessionStorage.getItem("memberUUID")
                            }, (responseData) => {
                                setAvailableMembers(responseData);
                                document.querySelector("#SelectMemberModal").style.display = "block";
                            }, () => {
                                alert("회원 목록 조회 실패");
                            });
                        }}>추가</button>
                    </div>
                    <div className="SelectedMemberList">
                        {selectedMemberList}
                    </div>
                    {dummyListView}
                </div>
                <button className="AddChatRoomButton" onClick={event => {
                    if (isNull(chattingRoomNameInput)) {
                        alert("채널 이름을 입력하세요.");
                        return;
                    }

                    if (isLoading) return;
                    setIsLoading(true);

                    createChattingRoom({
                            chattingRoomType: chattingRoomTypeInput,
                            chattingRoomTitle: chattingRoomNameInput
                        },
                        imageInput,
                        [...selectedManagers.map(data => data.uuid), ...selectedMembers.map(data => data.uuid), ...selectedDummies.map(data => data.uuid), sessionStorage.getItem("memberUUID")],
                        () => {
                            alert("채널이 추가되었습니다.");
                            window.location.href = '/chat';
                        }, () => {
                            setIsLoading(false);
                            alert("채널 추가 실패");
                        });
                }}>채널 추가하기</button>
            </section>

            <SelectManagerModal availableManagers={availableManagers} selectedManagers={selectedManagers} onAdd={(managers) => {
                setSelectedManagers(selectedManagers => [
                    ...selectedManagers,
                    ...managers
                ]);
            }} />

            <SelectMemberModal availableMembers={availableMembers} selectedMembers={selectedMembers} onAdd={(members) => {
                setSelectedMembers(selectedMembers => [
                    ...selectedMembers,
                    ...members
                ]);
            }}/>

            <SelectDummyModal availableDummies={availableDummies} selectedDummies={selectedDummies} onAdd={(dummies) => {
                setSelectedDummies(selectedDummies => [
                    ...selectedDummies,
                    ...dummies
                ]);
            }}/>
        </React.Fragment>
    );
}

function SelectManagerModal(props) {

    let [availableManagers, setAvailableManagers] = useState([]);
    let [keyword, setKeyword] = useState("");

    const keywordChanged = e => setKeyword(e.target.value);

    useEffect(() => {
        setAvailableManagers(props.availableManagers.filter(availableManager => {
            return !props.selectedManagers.some(selectedManager => selectedManager.id === availableManager.id);
        }));
    }, [props.availableManagers, props.selectedManagers]);

    window.onclick = ev => {
        let modal = document.querySelector("#SelectManagerModal")
        if (ev.target === modal) {
            modal.style.display = "none";
            setKeyword("");
        }
    }

    let managerList = isNull(availableManagers) ? "" :
        availableManagers.map(data =>
            <div key={data.id}>
                <input type="checkbox" name={data.name} value={JSON.stringify(data)} />
                <span>{`${data.nickname}(${data.name})`}</span>
            </div>
        );

    return (
            <div id="SelectManagerModal" className="modal">

                <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#SelectManagerModal").style.display = "none";
                        setKeyword("");
                    }}>&times;</span>
                    <h1>채널에 참여할 관리자</h1>
                    <div className="Form">
                        <div className="SearchArea">
                            <input type="text" value={keyword} onChange={keywordChanged}/>
                            <button onClick={event => {
                                getMembers({
                                    keyword: keyword,
                                    memberType: "MANAGER",
                                    memberUUID: sessionStorage.getItem("memberUUID")
                                }, (responseData) => {
                                    setAvailableManagers(responseData.filter(availableManager => {
                                        return !props.selectedManagers.some(selectedManager => selectedManager.id === availableManager.id);
                                    }));
                                }, () => {
                                    alert("검색 실패");
                                });
                            }}>검색</button>
                        </div>
                        <div className="MemberList">
                            {managerList}
                        </div>

                        <div className="ButtonList">
                            <button className="OK" onClick={event => {
                                let result = [];
                                document.querySelectorAll("#SelectManagerModal input[type=checkbox]").forEach(checkbox => {
                                    if (checkbox.checked) {
                                        result.push(JSON.parse(checkbox.value));
                                    }
                                });

                                if (isNull(result)) {
                                    alert("채널에 참여할 관리자를 선택해주세요!");
                                    return;
                                }

                                props.onAdd(result);
                                document.querySelector("#SelectManagerModal").style.display = "none";
                                setKeyword("");
                            }}>추가</button>
                        </div>
                    </div>
                </div>

            </div>
    );
}

function SelectMemberModal(props) {

    let [availableMembers, setAvailableMembers] = useState([]);
    let [keyword, setKeyword] = useState("");

    const keywordChanged = e => setKeyword(e.target.value);

    useEffect(() => {
        setAvailableMembers(props.availableMembers.filter(availableMember => {
            return !props.selectedMembers.some(selectedMember => selectedMember.id === availableMember.id);
        }));
    }, [props.availableMembers, props.selectedMembers]);

    window.onclick = ev => {
        let modal = document.querySelector("#SelectMemberModal")
        if (ev.target === modal) {
            modal.style.display = "none";
            setKeyword("");
        }
    }

    let memberList = isNull(availableMembers) ? "" :
        availableMembers.map(data =>
            <div key={data.id}>
                <input type="checkbox" name={data.name} value={JSON.stringify(data)} />
                <span>{`${data.nickname}(${data.name})`}</span>
            </div>
        );

    return (
        <div id="SelectMemberModal" className="modal">

            <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#SelectMemberModal").style.display = "none";
                        setKeyword("");
                    }}>&times;</span>
                <h1>채널에 참여할 회원</h1>
                <div className="Form">
                    <div className="SearchArea">
                        <input type="text" value={keyword} onChange={keywordChanged}/>
                        <button onClick={event => {
                            getMembers({
                                keyword: keyword,
                                memberType: "GENERAL",
                                memberUUID: sessionStorage.getItem("memberUUID")
                            }, (responseData) => {
                                setAvailableMembers(responseData.filter(availableMember => {
                                    return !props.selectedMembers.some(seletedMember => seletedMember.id === availableMember.id);
                                }));
                            }, () => {
                                alert("검색 실패");
                            });
                        }}>검색</button>
                    </div>
                    <div className="MemberList">
                        {memberList}
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={event => {
                            let result = [];
                            document.querySelectorAll("#SelectMemberModal input[type=checkbox]").forEach(checkbox => {
                                if (checkbox.checked) {
                                    result.push(JSON.parse(checkbox.value));
                                }
                            });

                            if (isNull(result)) {
                                alert("채널에 참여할 회원을 선택해주세요!");
                                return;
                            }

                            props.onAdd(result);
                            document.querySelector("#SelectMemberModal").style.display = "none";
                            setKeyword("");
                        }}>추가</button>
                    </div>
                </div>
            </div>

        </div>
    );
}

function SelectDummyModal(props) {

    let [availableDummies, setAvailableDummies] = useState([]);
    let [keyword, setKeyword] = useState("");

    const keywordChanged = e => setKeyword(e.target.value);

    useEffect(() => {
        setAvailableDummies(props.availableDummies.filter(availableDummy => {
            return !props.selectedDummies.some(selectedDummy => selectedDummy.id === availableDummy.id);
        }));
    }, [props.availableDummies, props.selectedDummies]);

    window.onclick = ev => {
        let modal = document.querySelector("#SelectDummyModal")
        if (ev.target === modal) {
            modal.style.display = "none";
            setKeyword("");
        }
    }

    let memberList = isNull(availableDummies) ? "" :
        availableDummies.map(data =>
            <div key={data.id}>
                <input type="checkbox" name={data.name} value={JSON.stringify(data)} />
                <span>{`${data.nickname}(${data.name})`}</span>
            </div>
        );

    return (
        <div id="SelectDummyModal" className="modal">

            <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#SelectDummyModal").style.display = "none";
                        setKeyword("");
                    }}>&times;</span>
                <h1>채널에 참여할 더미 회원</h1>
                <div className="Form">
                    <div className="SearchArea">
                        <input type="text" value={keyword} onChange={keywordChanged}/>
                        <button onClick={event => {
                            getMembers({
                                keyword: keyword,
                                memberType: "GENERAL",
                                memberUUID: sessionStorage.getItem("memberUUID")
                            }, (responseData) => {
                                setAvailableDummies(responseData.filter(availableDummies => {
                                    return !props.selectedDummies.some(selectedDummy => selectedDummy.id === availableDummies.id);
                                }));
                            }, () => {
                                alert("검색 실패");
                            });
                        }}>검색</button>
                    </div>
                    <div className="MemberList">
                        {memberList}
                    </div>

                    <div className="ButtonList">
                        <button className="OK" onClick={event => {
                            let result = [];
                            document.querySelectorAll("#SelectDummyModal input[type=checkbox]").forEach(checkbox => {
                                if (checkbox.checked) {
                                    result.push(JSON.parse(checkbox.value));
                                }
                            });

                            if (isNull(result)) {
                                alert("채널에 참여할 더미 회원을 선택해주세요!");
                                return;
                            }

                            props.onAdd(result);
                            document.querySelector("#SelectDummyModal").style.display = "none";
                            setKeyword("");
                        }}>추가</button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AddChatRoomView;