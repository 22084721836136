import React from "react";
import "./CircleImage.scss"
import {isNull} from "../../Common";

function CircleImage(props) {

    let style = {
        width: `${props.width}`
    }
    let content = "";

    if (isNull(props.imgSrc)) {
        style.background = '#EBEBEB';
        content = "";
    } else {
        style.backgroundImage = `url(${props.imgSrc})`;
    }

    return (
        <div className="CircleImage" style={style}
             onMouseOver={props.onMouseOver}
             onClick={props.onClick}
        >{content}</div>
    );
}

export default CircleImage;