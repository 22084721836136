import React, { useState, useEffect } from "react";
import TopBar from "../menu/TopBar";
import axios from "axios";
import "./MemberList.scss"
import {isNull, refreshToken} from "../../Common";
import AdminGroupDetail from "./AdminGroupDetail";

function getAdminGroupList(onLoad, onFail) {
    axios.get(
        "/admin/manager/group/list",
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getAdminGroupList(onLoad, onFail);
            });
        } else {
            onFail(error);
        }
    });
}

function addAdminGroup(name, onFail) {
    axios.post(
        "/admin/manager/group",
        {
            name: name
        },
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert(`'${name}' 관리자 그룹을 생성하였습니다.`);
        window.location.reload();
    }).catch(error => {
        const code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                addAdminGroup(name, onFail);
            });
        } else if (code === 603 || code === 604) {
            alert(error.response.data.message);
        } else {
            onFail();
            alert("관리자 그룹 생성 실패");
            console.log(error);
        }
    });
}

function AdminGroupList(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [adminGroupData, setAdminGroupData] = useState("");
    let [nameInput, setNameInput] = useState("");
    let [adminGroupDetailData, setAdminGroupDetailData] = useState();

    const nameInputChanged = e => setNameInput(e.target.value);

    useEffect(() => {
        window.scrollTo(0, 0);
        getAdminGroupList((responseData) => {
            setAdminGroupData(responseData);
        }, (error) => {
            alert("조회 실패");
            console.log(error);
        });

    }, []);

    let trList = isNull(adminGroupData) ? <tr><td colSpan={4}>검색 결과 없음</td></tr> : adminGroupData.map(data =>
        <tr key={data.id} onClick={event => {
            window.scrollTo(0, 0);

            setAdminGroupDetailData(data);
        }}>
            <td>{data.id}</td>
            <td>{data.name}</td>
        </tr>
    );

    let adminGroupDetailView = isNull(adminGroupDetailData) ? "" : <AdminGroupDetail adminGroupInfo={adminGroupDetailData} />

    window.onclick = ev => {
        let modal = document.querySelector("#AddAdminGroupModal")
        if (ev.target === modal) {
            modal.style.display = "none";
        }
    }

    return (
        <>
            <TopBar/>
            <div className="MemberListContainer">
                <div className="TableContainer">
                    <table>
                        <thead>
                        <tr>
                            <th>그룹번호</th>
                            <th>이름</th>
                        </tr>
                        </thead>
                        <tbody>
                        {trList}
                        </tbody>
                    </table>
                    <div className="ButtonList">
                        <button onClick={event => {
                            document.querySelector("#AddAdminGroupModal").style.display = "block";
                        }}>새 관리자 그룹 생성</button>
                    </div>
                </div>
                {adminGroupDetailView}
            </div>

            {/* The Modal */}
            <div id="AddAdminGroupModal" className="modal">

                {/* Modal content */}
                <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#AddAdminGroupModal").style.display = "none";
                    }}>&times;</span>
                    <h1>새 관리자 그룹 생성</h1>
                    <div className="MemberForm">
                        <div className="InputList">
                            <input type="text" placeholder="이름" value={nameInput} onChange={nameInputChanged} />
                        </div>

                        <div className="ButtonList">
                            <button className="OK" onClick={event => {
                                if (isLoading) return;
                                setIsLoading(true);

                                addAdminGroup(nameInput, () => {
                                    setIsLoading(false);
                                });
                            }}>등록</button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AdminGroupList;