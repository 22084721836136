import React, {useEffect, useState} from "react";
import axios from "axios";
import TopBar from "../menu/TopBar";
import {Link} from "react-router-dom";
import "./MemberList.scss"
import MemberDetail from "./MemberDetail";
import QueryString from "qs";
import {isNull, refreshToken} from "../../Common";

function getMemberList(searchKeyword, notConfirmedOnly, pageNumber, onLoad, onFail) {
    
    let params = {
        page: pageNumber
    }
    if (searchKeyword !== undefined && searchKeyword !== null && searchKeyword.length !== 0) {
        params.keyword = searchKeyword
    }
    if (notConfirmedOnly !== undefined && notConfirmedOnly !== null && notConfirmedOnly.length !== 0) {
        params.notConfirmedOnly = notConfirmedOnly
    }

    axios.get(
        "/admin/member/list",
        {
            params: params,
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMemberList(searchKeyword, notConfirmedOnly, pageNumber, onLoad, onFail);
            });
        } else {
            onFail(error);
        }
    });
}

function getMemberDetail(uuid, onLoad, onFail) {
    axios.get(
        "/admin/member/detail",
        {
            params: {
                uuid: uuid
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        const code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getMemberDetail(uuid, onLoad, onFail);
            });
        } else {
            onFail(error);
        }
    });
}


function signUpAdminMember(memberInfo, onSuccess, onFail) {
    axios.post(
        "/member/signup",
        memberInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(() => {
        alert("회원 등록 성공");
        onSuccess()
        window.location.reload();
    }).catch(error => {
        const code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                signUpAdminMember(memberInfo, onFail);
            });
        } else if (code === 603 || code === 604) {
            alert(error.response.data.message);
            onFail();
        } else {
            onFail();
            alert("회원 등록 실패");
            console.log(error);
        }
    });
}


function MemberList(props) {

    const params = QueryString.parse(props.location.search.replace('?', ''));

    let pageNum = parseInt(props.match.params.pageNumber) - 1
    let [memberData, setMemberData] = useState("");
    let [searchKeyword, setSearchKeyword] = useState(params.searchKeyword);
    let [filterNotConfirmed, setFilterNotConfirmed] = useState(isNull(params.notConfirmedOnly) ? false : JSON.parse(params.notConfirmedOnly));
    let [memberDetailData, setMemberDetailData] = useState('');

    let [isLoading, setIsLoading] = useState(false);
    let [adminGroupData, setAdminGroupData] = useState([]);
    let [adminGroupIdInput, setAdminGroupIdInput] = useState();
    let [idInput, setIdInput] = useState("");
    let [pwdInput, setPwdInput] = useState("");
    let [nameInput, setNameInput] = useState("");
    let [nicknameInput, setNicknameInput] = useState("");
    let [phoneInput, setPhoneInput] = useState("");

    const adminGroupIdInputChanged = e => setAdminGroupIdInput(e.target.value);
    const searchKeywordChanged = e => setSearchKeyword(e.target.value);
    const idInputChanged = e => setIdInput(e.target.value.trim());
    const pwdInputChanged = e => setPwdInput(e.target.value.trim());
    const nameInputChanged = e => setNameInput(e.target.value.trim());
    const nicknameInputChanged = e => setNicknameInput(e.target.value.trim());
    const phoneInputChanged = e => setPhoneInput(e.target.value.trim());

    const filterNotConfirmedChanged = e => {
        const url = `/member/list/GENERAL/${props.match.params.pageNumber}?`;

        let query = {};
        if (searchKeyword !== undefined && searchKeyword !== null && searchKeyword.length !== 0) {
            query.searchKeyword = searchKeyword
        }
        query.notConfirmedOnly = e.target.checked;
        const queryString = Object.entries(query).map(e => e.join("=")).join("&");

        window.location.href = `${url}${queryString}`;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getMemberList(searchKeyword, filterNotConfirmed, pageNum, (responseData) => {
            setMemberData(responseData);
        }, (error) => {
            alert("조회 실패");
            console.log(error);
        });
    }, [props.match.params.pageNumber]);

    let trList = isNull(memberData) || isNull(memberData.content) ? <tr><td colSpan={4}>검색 결과 없음</td></tr> : memberData.content.map(data =>
        <tr className={data.isConfirmed ? "" : "NotConfirmed"} key={data.uuid} onClick={() => {
            window.scrollTo(0, 0);
            getMemberDetail(data.uuid, (responseData) => {
                setMemberDetailData(responseData);
            }, (error) => {
                alert("회원 상세정보 조회 실패");
                console.log(error);
            });
        }}>
            <td>{data.id}</td>
            <td>{data.name}</td>
            <td>{data.nickname}</td>
            <td>{data.phone}</td>
        </tr>
    );

    let minPage = pageNum - (pageNum % 10) + 1
    let maxPage = pageNum - (pageNum % 10) + 10
    if (maxPage > memberData.totalPages) {
        maxPage = memberData.totalPages
    }

    let pageList = []

    let query = {};
    if (searchKeyword !== undefined && searchKeyword !== null && searchKeyword.length !== 0) {
        query.searchKeyword = searchKeyword
    }
    if (filterNotConfirmed !== undefined && filterNotConfirmed !== null) {
        query.notConfirmedOnly = filterNotConfirmed
    }
    const queryString = Object.entries(query).map(e => e.join("=")).join("&");

    for (let i = minPage; i <= maxPage; i++) {
        let url = `/member/list/GENERAL/${i}?`;

        pageList.push(
            <Link to={`${url}${queryString}`} key={i}>
                {i}
            </Link>
        );
    }
    if (minPage > 1) {
        let url = `/member/list/GENERAL/${minPage - 1}?`;

        pageList.unshift(
            <Link to={`${url}${queryString}`} key={'prev'}>
                이전
            </Link>
        )
    }
    if (maxPage < memberData.totalPages) {
        let url = `/member/list/GENERAL/${maxPage + 1}?`;

        pageList.push(
            <Link to={`${url}${queryString}`} key={'next'}>
                다음
            </Link>
        );
    }

    window.onclick = ev => {
        let modal = document.querySelector("#SignUpModal");
        if (ev.target === modal) {
            modal.style.display = "none";
        }
    }

    let memberDetailView = memberDetailData === undefined || memberDetailData.length === 0 ? "" :
        <MemberDetail memberInfo={memberDetailData} onReload={() => {
            getMemberDetail(memberDetailData.uuid, (responseData) => {
                setMemberDetailData(responseData);
            }, (error) => {
                alert("회원 상세정보 조회 실패");
                console.log(error);
            });
        }}/>

        let options = isNull(adminGroupData) ? "" :
        <React.Fragment>
            <option selected disabled>그룹</option>
            {adminGroupData.map(data => <option value={data.id}>{data.name}</option>)}
        </React.Fragment>

    return (
        <>
            <TopBar/>
            <div className="MemberListContainer">
                <div className="TableContainer">
                    <div className="SearchBarWrapper">
                        <input type="text" placeholder={"검색"} value={searchKeyword || ""} onChange={searchKeywordChanged} onKeyUp={event => {
                            if (event.keyCode === 13) { //enter
                                window.location.href = `/member/list/GENERAL/${props.match.params.pageNumber}?searchKeyword=${searchKeyword}&notConfirmedOnly=${filterNotConfirmed}`;
                            }
                        }}/>
                        <button onClick={event => {
                            window.location.href = `/member/list/GENERAL/${props.match.params.pageNumber}?searchKeyword=${searchKeyword}&notConfirmedOnly=${filterNotConfirmed}`;
                        }}>검색</button>
                        <input type="checkbox" checked={filterNotConfirmed} onChange={filterNotConfirmedChanged}/>
                        <span>미승인 회원만 보기</span>


            <button 
            style={{ marginLeft: 'auto'}}
            onClick={(event) => {
                            // console.log(document.querySelector("#SignUpModal"))
                            document.querySelector("#SignUpModal").style.display = "block";
                        }}
                        >회원 등록</button>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>계정</th>
                            <th>이름</th>
                            <th>닉네임</th>
                            <th>연락처</th>
                        </tr>
                        </thead>
                        <tbody>
                        {trList}
                        </tbody>
                    </table>
                    <div className="ButtonList">
                        <div style={{flexGrow: "1"}}/>
                        <div className="PageList">
                            {pageList}
                        </div>
                    </div>
                </div>
                {memberDetailView}
            </div>

            {/* The Modal */}
            <div id="SignUpModal" className="modal">

                {/* Modal content */}
                <div className="modal-content">
                    <span className="close" onClick={event => {
                        document.querySelector("#SignUpModal").style.display = "none";
                    }}>&times;</span>
                    <h1> 계정 생성/등록</h1>
                    <div className="MemberForm">
                        <div className="InputList">
                            {/* <select value={adminGroupIdInput} onChange={adminGroupIdInputChanged}>
                                {options}
                            </select> */}
                            <input type="text" placeholder="계정" value={idInput} onChange={idInputChanged}/>
                            <input type="password" placeholder="비밀번호" value={pwdInput} onChange={pwdInputChanged}/>
                            <input type="text" placeholder="이름" value={nameInput} onChange={nameInputChanged}/>
                            <input type="text" placeholder="닉네임" value={nicknameInput} onChange={nicknameInputChanged}/>
                            <input type="text" placeholder="전화번호" value={phoneInput} onChange={phoneInputChanged}/>
                        </div>

                        <div className="ButtonList">
                            <button className="OK" onClick={() => {
                                if (isLoading) return;
                                setIsLoading(true);

                                signUpAdminMember({
                                    id: idInput,
                                    pwd: pwdInput,
                                    type: "GENERAL",
                                    name: nameInput,
                                    nickname: nicknameInput,
                                    phone: phoneInput,
                                    managerGroupId: adminGroupIdInput
                                }, () => {
                                    setIsLoading(false);
                                }, () => {
                                    setIsLoading(false);
                                });
                            }}>등록
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default MemberList;