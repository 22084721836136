import React, {useEffect, useState} from "react";
import CircleImage from "../common/CircleImage";
import axios from "axios";
import {isNull, refreshToken} from "../../Common";

function modifyAdminGroupName(modifyInfo, onFail) {
    axios.put(
        "/admin/manager/group",
        modifyInfo,
        {
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        alert("수정되었습니다.");
        window.location.reload();
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                modifyAdminGroupName(modifyInfo, onFail);
            });
        } else {
            onFail();
            alert("수정 실패");
            console.log(error);
        }
    });
}

function removeMember(adminGroupId, uuid, onLoad, onFail) {
    axios.delete(
        "/admin/member",
        {
            params: {
                uuid: uuid
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        getAdminGroupMembers(adminGroupId, onLoad, onFail);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                removeMember(adminGroupId, uuid, onLoad, onFail)
            });
        } else {
            alert("삭제 실패");
            console.log(error);
        }
    });
}

function getAdminGroupMembers(adminGroupId, onLoad, onFail) {
    axios.get(
        "/admin/manager/group/member/list",
        {
            params: {
                managerGroupId: adminGroupId
            },
            headers: {
                Authorization: sessionStorage.getItem("accessToken")
            }
        }
    ).then(response => {
        onLoad(response.data);
    }).catch(error => {
        let code = error.response.data.code;

        if (code === 600 || code === 601) {
            refreshToken(() => {
                getAdminGroupMembers(adminGroupId, onLoad, onFail)
            });
        } else {
            console.log(error);
            onFail();
        }
    });
}

function AdminGroupDetail(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [isModify, setIsModify] = useState(false);
    let [nameInput, setNameInput] = useState(props.adminGroupInfo.name);
    let [adminGroupMemberData, setAdminGroupMemberData] = useState();

    const nameInputChanged = e => setNameInput(e.target.value);

    useEffect(() => {
        setNameInput(props.adminGroupInfo.name);
        getAdminGroupMembers(props.adminGroupInfo.id, (responseData) => {
            setAdminGroupMemberData(responseData);
        }, () => {
            alert("그룹 구성원 조회 실패");
        });
    }, [props.adminGroupInfo]);

    let infoTextArea = isModify ? (
        <div className="InfoTextArea">
            <span>그룹 번호: {props.adminGroupInfo.id}</span>
            <input type="text" value={nameInput} onChange={nameInputChanged}/>
        </div>
    ) : (
        <div className="InfoTextArea">
            <span>그룹 번호: {props.adminGroupInfo.id}</span>
            <span>{nameInput}</span>
        </div>
    );

    let buttonArea = isModify ? (
        <div className="ButtonArea">
            <button className="OK" onClick={event => {
                if(isLoading || !window.confirm("정말 수정하시겠습니까?")) return;
                setIsLoading(true);

                modifyAdminGroupName({
                    managerGroupId: props.adminGroupInfo.id,
                    name: nameInput
                }, () => {
                    setIsLoading(false);
                });
            }}>확인</button>
            <button className="Cancel" onClick={event => {
                setIsModify(false);
            }}>취소</button>
        </div>
    ) : (
        <div className="ButtonArea">
            <button className="Modify" onClick={event => {
                setIsModify(true);
            }}>수정</button>
            {/*<button className="Remove" onClick={event => {*/}
            {/*    if(!window.confirm("정말 삭제하시겠습니까?")) return;*/}

            {/*    //TODO: 관리자 그룹 삭제*/}
            {/*}}>삭제</button>*/}
        </div>
    );

    let adminGroupMemberList = isNull(adminGroupMemberData) ? <tr>
        <td colSpan={3}><p style={{color: "red"}} >그룹에 포함된 관리자 계정이 아직 없습니다.</p></td>
    </tr> : adminGroupMemberData.map(data => <tr key={data.uuid}>
        <td>{data.id}</td>
        <td>{data.nickname}</td>
        <td><button onClick={() => {
            if (isLoading || !window.confirm(`계정을 삭제하면 해당 계정으로 로그인할 수 없습니다.\n'${data.id}'계정을 삭제하시겠습니까?`)) return;
            setIsLoading(true);

            removeMember(props.adminGroupInfo.id, data.uuid, (responseData) => {
                setAdminGroupMemberData(responseData);
            }, () => {
                setIsLoading(false);
                alert("그룹 구성원 조회 실패");
            });
        }}>삭제</button></td>
    </tr>);

    return (
        <div className="MemberDetailContainer">

            <div className="MemberInfoArea">
                {infoTextArea}
                {buttonArea}
            </div>

            <div className="ManagerGroupMemberList">
                <table>
                    <thead>
                        <tr>
                            <th>계정</th>
                            <th>닉네임</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {adminGroupMemberList}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdminGroupDetail;